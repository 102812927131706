import { Checkbox } from 'lib/components/FormFields';
import React, { useState } from 'react';
import classes from './index.module.scss';
import faker from 'faker';
import Typography from 'lib/components/Typography';
import Image from 'lib/components/Image';
import { useWindowResize } from 'hooks/useWindowResize';
import Scrollbars from 'react-custom-scrollbars';

interface Props {}

const dataTableHeaderItems = ['Requested By', 'Ticket ID', 'Sent to', 'Amount', 'Price', 'Created Date'];
const dataTableValues = new Array(10).fill('').map((_, index) => {
	const record = {
		checked: index === 0,
		requestedBy: 'Spencer Memorial Hospital',
		ticketID: '0001478',
		sentTo: 'Regina Smith',
		amount: faker.random.number(100),
		price: faker.random.number({ max: 1100, min: 10, precision: 2 }) + '$',
		createdDate: '01.01.21 - 09:45AM',
	};

	return record;
});

const pages = [1, 2, 3, 4];

const InvoicesDataTable = ({}: Props) => {
	const [allChecked, setAllChecked] = useState(false);
	const [selectedPage, setSelectedPage] = useState(1);
	const { isSmallerDevice } = useWindowResize();
	return (
		<div className={classes['invoices-datatable']}>
			<div className={classes['table-wrapper']}>
				<div className={classes['table-container']}>
					<div className={classes['toolbar']}>
						<Checkbox
							checked={allChecked}
							onChange={() => setAllChecked(!allChecked)}
							style={{
								margin: '0 2rem 0.1rem 2rem',
							}}
						/>
						{dataTableHeaderItems.map((label, index) => (
							<div className={classes['col']} key={index}>
								<Typography variant={isSmallerDevice ? 'h5' : 'h6'} weight="light">
									{label}
								</Typography>
								<Image
									src="icArrowDown"
									style={{
										marginLeft: '0.5rem',
									}}
								/>
							</div>
						))}
					</div>
					<div className={classes['container']}>
						<Scrollbars
							style={{
								flex: 1,
							}}
						>
							{dataTableValues.map(
								({ amount, createdDate, price, sentTo, checked, requestedBy, ticketID }, index) => (
									<div key={index} className={classes['table-row']}>
										<Checkbox
											checked={checked}
											style={{
												margin: '0 2rem',
											}}
										/>
										<Typography variant={'h6'} className={classes['col']}>
											{requestedBy}
										</Typography>
										<Typography variant={'h6'} className={classes['col']}>
											{ticketID}
										</Typography>
										<Typography variant={'h6'} className={classes['col']}>
											{sentTo}
										</Typography>
										<Typography variant={'h6'} className={classes['col']}>
											{amount}
										</Typography>
										<Typography variant={'h6'} className={classes['col']}>
											{price}$
										</Typography>
										<Typography variant={'h6'} className={classes['col']}>
											{createdDate}
										</Typography>
									</div>
								)
							)}
						</Scrollbars>
					</div>
				</div>
			</div>
			<div className={classes['bottom-toolbar']}>
				<button className={classes['badge']}>
					<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
						First
					</Typography>
				</button>
				<button className={classes['badge']}>
					<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
						Prev.
					</Typography>
				</button>
				{pages.map((page) => {
					const classname =
						page === selectedPage ? `${classes['badge']} ${classes['active']}` : `${classes['badge']}`;
					return (
						<button className={classname} key={page.toString()} onClick={() => setSelectedPage(page)}>
							<Typography
								variant={isSmallerDevice ? 'h5' : 'h6'}
								color={page === selectedPage ? 'primary' : 'darkgrey'}
							>
								{page}
							</Typography>
						</button>
					);
				})}

				<button className={classes['badge']}>
					<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
						Next
					</Typography>
				</button>
				<button className={classes['badge']}>
					<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
						Last
					</Typography>
				</button>
			</div>
		</div>
	);
};

export default InvoicesDataTable;
