const selectImageHandler = (onFileSelectedSuccess: (file: File, fileURL: any) => any, fileSizeLimit?: number) => {
	return (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files && e.target.files[0];
		let fileURL;

		if (fileSizeLimit && fileSizeLimit < file!.size) {
			throw new Error('File exceeded fileSizeLimit');
		}

		const fileReader = new FileReader();
		fileReader.addEventListener('load', (ev) => {
			fileURL = ev.target?.result;
			onFileSelectedSuccess(file as File, fileURL);
		});

		fileReader.readAsDataURL(file as File);

		// initiate api call that creates a formData and sends it to the server
	};
};

export default selectImageHandler;
