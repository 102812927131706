import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Public from './public';
import Authenticated from './authenticated';
import Auth from 'lib/components/Auth';
import ProfileScreen from 'screens/ProfileScreen';
import EmployeesScreen from 'screens/EmployeesScreen';
import EmployeeScreen from 'screens/EmployeesScreen/Employee';
import EmployeeCreateScreen from 'screens/EmployeesScreen/EmployeeCreate';
import JobsScreen from 'screens/JobsScreen';
import JobScreen from 'screens/JobsScreen/Job';
// import ActiveJobScreen from 'screens/JobsScreen/ActiveJob';
import CreateJob from 'screens/JobsScreen/CreateJob';
import CompleteJob from 'screens/JobsScreen/CompleteJob';
import DashboardScreen from 'screens/Dashboard';
import Logout from 'lib/components/Auth/logout';

const Routes = () => {
	return (
		<Switch>
			<Public exact path="/login" component={Auth} />
			<Authenticated exact path={'/profile'} component={ProfileScreen} />
			<Authenticated exact path={'/logout'} component={Logout} />
			<Authenticated exact path={'/staff'} component={EmployeesScreen} />
			<Authenticated exact path={'/staff/view/:id'} component={EmployeeScreen} />
			<Authenticated exact path={'/staff/create'} component={EmployeeCreateScreen} />
			<Authenticated exact path={'/dashboard'} component={DashboardScreen} />
			<Authenticated exact path={'/jobs'} component={JobsScreen} />
			<Authenticated exact path={'/jobs/view/:id'} component={JobScreen} />
			{/* <Authenticated exact path={'/jobs/active/:id'} component={ActiveJobScreen} /> */}
			<Authenticated exact path={'/jobs/create'} component={CreateJob} />
			<Authenticated exact path={'/jobs/complete/:id'} component={CompleteJob} />
			<Redirect exact from="/" to="login" />
		</Switch>
	);
};

export default Routes;
