const colors = {
	primary: '#44AF69',
	primaryOpacity: '#8fcfa5',
	accent: '#FF0000',
	primaryText: '#FFFFFF',
	secondaryText: '#BEC1C3',
	tertiaryText: '#82868C',
	errorText: '#D62839',
	backgroundColor: '#2E323A',
	onBackgroundColor: '#FFFFFF',
	starColor: '#FFD400',
	lightBlue: '#E7F7F9',
};

export default colors;
