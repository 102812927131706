import React, { useEffect, useState } from 'react';
import useComprehensiveTopNav from 'hooks/useComprehensiveTopNav';
import { Button, Typography, Image, LoadingScreen } from 'lib/components';
import { useAppDispatch, actions, useSelectJobs } from 'store';
import { PendingJobs, ActiveJobs, CompletedJobs } from './DataTables';
import classes from './index.module.scss';
import { useHistory } from 'react-router-dom';

const DashboardScreen = () => {
	const dispatch = useAppDispatch();
	const jobs = useSelectJobs();
	const { push } = useHistory();

	const [isDataFetched, setIsDataFetched] = useState(false);

	useEffect(() => {
		dispatch(actions.fetchJobs());
	}, []);

	useEffect(() => {
		setIsDataFetched(true);
	}, [jobs.updatedAt]);

	useComprehensiveTopNav({
		contentHeader: 'Dashboard',
		showMapToggle: true,
		showSearchBar: false,
	});

	if (!isDataFetched) return <LoadingScreen />;

	return (
		<div className={classes['dashboard']}>
			<div className={classes['dashboard-left-container']}>
				<section className={`${classes['dashboard-section']} ${classes['dashboard-jobRequests']}`}>
					<div className={classes['dashboard-section-header']}>
						<Typography className={classes['dashboard-section-header-title']} variant="h4">
							Your Created Jobs Awaiting Approval
						</Typography>
						<Button
							className={classes['dashboard-section-header-button']}
							title="See all requests"
							color="primary"
							sizing="small"
							onClick={() => push('/jobs?filter=pending')}
							end={<Image src={'icRightCaretPrimary'} />}
						/>
					</div>
					<PendingJobs jobs={jobs.list.filter((job) => job.status === 0).slice(0, 10)} />
				</section>
				<section className={`${classes['dashboard-section']} ${classes['dashboard-activeJobs']}`}>
					<div className={classes['dashboard-section-header']}>
						<Typography className={classes['dashboard-section-header-title']} variant="h4">
							Your Currently Active Jobs
						</Typography>
						<Button
							className={classes['dashboard-section-header-button']}
							title="See all active jobs"
							color="primary"
							sizing="small"
							onClick={() => push('/jobs?filter=approved')}
							end={<Image src={'icRightCaretPrimary'} />}
						/>
					</div>
					<ActiveJobs jobs={jobs.list.filter((job) => [1, 2, 4, 5, 6].includes(job.status)).slice(0, 10)} />
				</section>
			</div>
			<div className={classes['jobs-right-container']}>
				<section className={`${classes['dashboard-section']} ${classes['dashboard-activeJobs']}`}>
					<div className={classes['dashboard-section-header']}>
						<Typography className={classes['dashboard-section-header-title']} variant="h4">
							Completed Jobs
						</Typography>
						<Button
							className={classes['dashboard-section-header-button']}
							title="See all completed jobs"
							color="primary"
							sizing="small"
							onClick={() => push('/jobs?filter=approved')}
							end={<Image src={'icRightCaretPrimary'} />}
						/>
					</div>
					<CompletedJobs jobs={jobs.list.filter((job) => job.status === 7).slice(0, 10)} />
				</section>
			</div>
		</div>
	);
};

export default DashboardScreen;
