import { combineReducers } from 'redux';
import request from './slices/request.slice';
import auth, { authThunks } from './slices/auth.slice';
import user, { userThunks } from './slices/user.slice';
import employees, { employeeThunks } from './slices/employees.slice';
import jobs, { jobThunks } from './slices/jobs.slice';
import nav from './slices/nav.slice';
import jobsSlice from './slices/jobs.slice';

export const actions = {
	...authThunks,
	...userThunks,
	...employeeThunks,
	...jobThunks,
	...request.actions,
	...nav.actions,
	...auth.actions,
	...user.actions,
	...employees.actions,
	...jobsSlice.actions,
};

export default combineReducers({
	nav: nav.reducer,
	request: request.reducer,
	authentication: auth.reducer,
	user: user.reducer,
	employees: employees.reducer,
	jobs: jobs.reducer,
});
