import { Employee } from 'types';
import { Sort } from './types';

export const staffIdCompareFn = (a: Employee, b: Employee, sort: Sort) => {
	if (a.id > b.id) {
		return sort === 'asc' ? 1 : -1;
	} else if (a.id < b.id) {
		return sort === 'asc' ? -1 : 1;
	}
	return 0;
};
