import { JobStatusType as S } from '.';

export default class JobStatus {
	static readonly PENDING = 0;
	static readonly ACCEPTED = 1;
	static readonly NURSE_ACCEPTED = 2;
	static readonly NURSE_REJECTED = 3;
	static readonly STARTED = 4;
	static readonly REACHED = 5;
	static readonly WORKING = 6;
	static readonly FINISHED = 7;
	static readonly CLOSED = 8;
	static readonly CANCELLED = 9;
	static readonly VOIDED = 10;

	private static readonly STATUS_TEXT = {
		[JobStatus.PENDING]: 'Pending',
		[JobStatus.ACCEPTED]: 'Accepted',
		[JobStatus.NURSE_ACCEPTED]: 'Nurse Accepted',
		[JobStatus.NURSE_REJECTED]: 'Nurse Rejected',
		[JobStatus.STARTED]: 'Started',
		[JobStatus.REACHED]: 'Reached',
		[JobStatus.WORKING]: 'Working',
		[JobStatus.FINISHED]: 'Finished',
		[JobStatus.CLOSED]: 'Closed',
		[JobStatus.CANCELLED]: 'Canceled',
		[JobStatus.VOIDED]: 'Voided',
	};

	public static progressText = (status: S) => {
		if ([S.PENDING, S.ACCEPTED, S.NURSE_ACCEPTED].includes(status)) return 'Not Started';
		if (status === S.STARTED) return 'Nurse en route to Hospital';
		if (status === S.REACHED) return 'Nurse reached Hospital';
		if (status === S.WORKING) return 'Nurse currently working';
		if ([S.FINISHED, S.CLOSED].includes(status)) return 'Nurse completed job';
		if (status === S.NURSE_REJECTED) return 'Nurse rejected job';
		if (status === S.CANCELLED) return 'Job cancelled';
		if (status === S.VOIDED) return 'Job voided';
	};

	public static progressFractional = (status: S): string => {
		if (status === S.PENDING) return '1 of 7';
		if (status === S.ACCEPTED) return '2 of 7';
		if (status === S.NURSE_ACCEPTED) return '3 of 7';
		if (status === S.STARTED) return '4 of 7';
		if (status === S.REACHED) return '5 of 7';
		if (status === S.WORKING) return '6 of 7';
		if ([S.FINISHED, S.CLOSED].includes(status)) return '7 of 7';
		return '';
	};

	public static progressPercentage = (status: S): number => {
		if (status === S.PENDING) return (1 / 7) * 100;
		if (status === S.ACCEPTED) return (2 / 7) * 100;
		if (status === S.NURSE_ACCEPTED) return (3 / 7) * 100;
		if (status === S.STARTED) return (4 / 7) * 100;
		if (status === S.REACHED) return (5 / 7) * 100;
		if (status === S.WORKING) return (6 / 7) * 100;
		if ([S.FINISHED, S.CLOSED].includes(status)) return 100;
		else return 0;
	};

	public static text = (type: S) => JobStatus.STATUS_TEXT[type];
}
