import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import moment from 'moment';
import { Job, JobFile, JobInput } from 'types';
import { AppThunk, actions, JobState } from '..';
import Api from 'lib/api';
import routes from 'lib/api/routes';

const initialState: JobState = {
	updatedAt: moment.utc().valueOf(),
	documents: [],
	list: [],
};

export default createSlice({
	name: 'jobs',
	initialState,
	reducers: {
		jobsFetched: (state, { payload }: PA<Job[]>) => {
			state.updatedAt = moment.utc().valueOf();
			state.list = payload;
		},
		jobFetched: (state, { payload }: PA<Job>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload.id);

			console.log({ list });
			if (index !== -1) {
				list.splice(index, 1);
			}

			list.push(payload);

			state.updatedAt = moment.utc().valueOf();
			state.list = list;
		},
		documentUploaded: (state, { payload }: PA<JobFile>) => {
			const uploads = [...state.documents];
			uploads.push(payload);

			state.documents = uploads;
			state.updatedAt = moment.utc().valueOf();
		},
		documentRemoved: (state, { payload }: PA<number>) => {
			const uploads = [...state.documents];
			const index = uploads.findIndex(({ id }) => id === payload);

			if (index !== -1) {
				uploads.splice(index, 1);
			}

			state.documents = uploads;
			state.updatedAt = moment.utc().valueOf();
		},
		documentsRemoved: (state) => {
			state.documents = [];
			state.updatedAt = moment.utc().valueOf();
		},
	},
});

const fetchJobs = (): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.JOB, method: 'GET' }));

		const { data, error } = await Api.jobs.getAll();

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.JOB,
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.JOB,
					method: 'GET',
					message: 'Jobs fetched successfully',
					payload: {},
				})
			);
			dispatch(actions.jobsFetched(data!));
		}
	};
};

const fetchJob = (id: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.JOB_ID(id), method: 'GET' }));

		const { data, error } = await Api.jobs.getOne(id);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.JOB_ID(id),
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.jobFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.JOB_ID(id),
					method: 'GET',
					message: 'Job fetched successfully',
					payload: {},
				})
			);
		}
	};
};

const addJob = (input: JobInput): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.JOB, method: 'POST' }));

		const { data, error } = await Api.jobs.create(input);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.JOB,
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.JOB,
					method: 'POST',
					message: 'Job added successfully',
					payload: { ...data },
				})
			);
			dispatch(actions.fetchJobs());
		}
	};
};

const uploadDocument = (file: File): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.UPLOAD_DOCUMENT, method: 'POST' }));

		const { data, error } = await Api.jobs.upload.document(file);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.UPLOAD_DOCUMENT,
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.documentUploaded(data!));
			dispatch(
				actions.requestFinished({
					name: routes.UPLOAD_DOCUMENT,
					method: 'POST',
					message: 'File uploaded successfully',
					payload: {},
				})
			);
		}
	};
};

const updateJob = (id: number, input: JobInput): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.JOB_ID(id), method: 'PUT' }));

		const { data, error } = await Api.jobs.update(id, input);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.JOB_ID(id),
					method: 'PUT',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.jobFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.JOB_ID(id),
					method: 'PUT',
					message: 'Job updated successfully',
					payload: {},
				})
			);
		}
	};
};

const cancelJob = (id: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.JOB_ID(id), method: 'POST' }));

		const { data, error } = await Api.jobs.cancel(id);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.JOB_ID(id),
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.JOB_ID(id),
					method: 'POST',
					message: 'Job cancelled successfully',
					payload: { ...data },
				})
			);
			dispatch(actions.fetchJob(id));
		}
	};
};

export const jobThunks = {
	fetchJob,
	fetchJobs,
	addJob,
	uploadDocument,
	updateJob,
	cancelJob,
};
