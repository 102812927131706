import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import {
	ApiResponse,
	AuthData,
	CurrentUserUpdateInput,
	Employee,
	EmployeeCreateInput,
	EmployeeUpdateInput,
	Job,
	JobFile,
	JobInput,
	OK,
	User,
} from 'types';
import routes from './routes';

class Api {
	private readonly BASE_URL: string = process.env.REACT_APP_API_URL!;
	private readonly pub: AxiosInstance;
	private readonly _auth: AxiosInstance;

	constructor() {
		const config: AxiosRequestConfig = { baseURL: `${this.BASE_URL}/api/v1` };

		const interceptorResponseFn = (response: AxiosResponse<any>): AxiosResponse<any> => response.data;

		const interceptorErrorFn = (error: any) => {
			return Promise.reject({
				status: error.response.status,
				message: error.response.data.errors[0].msg,
			});
		};

		this.pub = axios.create(config);
		this.pub.interceptors.response.use(interceptorResponseFn, interceptorErrorFn);

		this._auth = axios.create(config);
		this._auth.interceptors.request.use(
			(config) => {
				const auth = window.localStorage.getItem('auth');

				if (auth) {
					const { accessToken } = JSON.parse(auth) as AuthData;
					config.headers.Authorization = `Bearer ${accessToken}`;
				}
				return config;
			},
			(err) => Promise.reject(err)
		);
		this._auth.interceptors.response.use(interceptorResponseFn, interceptorErrorFn);
	}

	//* Public getters for accessing Api methods in a form of a collection
	public get auth() {
		return {
			signin: this.getAccessToken,
			signout: this.signout,
		};
	}

	public get avatar() {
		return {
			upload: this.uploadAvatar,
		};
	}

	public get user() {
		return {
			profile: this.getCurrentUser,
			update: this.updateCurrentUser,
			getById: this.getUserById,
		};
	}

	public get employees() {
		return {
			getOne: this.getEmployeeById,
			getAll: this.getAllEmployees,
			create: this.addEmployee,
			update: this.updateEmployee,
			delete: this.deleteEmployee,
			toggleSuspend: this.toggleEmployeeSuspend,
		};
	}

	public get jobs() {
		return {
			getOne: this.getJob,
			getAll: this.getAllJobs,
			create: this.addJob,
			update: this.updateJob,
			cancel: this.cancelJob,
			upload: {
				document: this.uploadDocument,
			},
		};
	}

	// Auth Methods
	private getAccessToken = async (uniqueId: string): Promise<ApiResponse<AuthData>> => {
		try {
			const data = await this.pub.post<{ uniqueId: string }, AuthData>(routes.AUTH_CALLBACK, { uniqueId });
			return { data };
		} catch (error) {
			return { error };
		}
	};

	private signout = async (): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.get<any, OK>(routes.SIGN_OUT);
			return { data };
		} catch (error) {
			return { error };
		}
	};

	// User methods
	private getCurrentUser = async (): Promise<ApiResponse<User>> => {
		try {
			const data = await this._auth.get<any, User>(routes.USER);
			return { data };
		} catch (error) {
			return { error };
		}
	};

	private getUserById = async (id: number): Promise<ApiResponse<User>> => {
		try {
			const data = await this._auth.get<any, User>(routes.USER_ID(id));
			return { data };
		} catch (error) {
			return { error };
		}
	};

	private updateCurrentUser = async (input: CurrentUserUpdateInput): Promise<ApiResponse<User>> => {
		try {
			const data = await this._auth.put<CurrentUserUpdateInput, User>(routes.USER, input);
			return { data };
		} catch (error) {
			return { error };
		}
	};

	private uploadAvatar = async (id: number, file: File): Promise<ApiResponse<OK>> => {
		try {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('type', 'avatar');

			const data = await this._auth.post<FormData, OK>(routes.UPLOAD_ID(id), formData);

			return { data };
		} catch (error) {
			return { error };
		}
	};

	private uploadDocument = async (file: File): Promise<ApiResponse<JobFile>> => {
		try {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('type', 'document');

			const data = await this._auth.post<FormData, JobFile>(routes.UPLOAD_DOCUMENT, formData);

			return { data };
		} catch (error) {
			return { error };
		}
	};

	// Employee methods
	private getAllEmployees = async (): Promise<ApiResponse<Employee[]>> => {
		try {
			const data = await this._auth.get<any, Employee[]>(routes.EMPLOYEE);
			return { data };
		} catch (error) {
			return { error };
		}
	};

	private getEmployeeById = async (id: number): Promise<ApiResponse<Employee>> => {
		try {
			const data = await this._auth.get<any, Employee>(routes.EMPLOYEE_ID(id));
			return { data };
		} catch (error) {
			return { error };
		}
	};

	private addEmployee = async (input: EmployeeCreateInput): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.post<EmployeeCreateInput, OK>(routes.EMPLOYEE, input);
			return { data };
		} catch (error) {
			return { error };
		}
	};

	private updateEmployee = async (id: number, input: EmployeeUpdateInput): Promise<ApiResponse<Employee>> => {
		try {
			const data = await this._auth.put<EmployeeUpdateInput, Employee>(routes.EMPLOYEE_ID(id), input);
			return { data };
		} catch (error) {
			return { error };
		}
	};

	private deleteEmployee = async (id: number): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.delete<any, OK>(routes.EMPLOYEE_ID(id));
			return { data };
		} catch (error) {
			return { error };
		}
	};

	private toggleEmployeeSuspend = async (id: number): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.post<any, OK>(routes.EMPLOYEE_SUSPEND(id));
			return { data };
		} catch (error) {
			return { error };
		}
	};

	// Job methods
	private getAllJobs = async (): Promise<ApiResponse<Job[]>> => {
		try {
			const data = await this._auth.get<any, Job[]>(routes.JOB);
			return { data };
		} catch (error) {
			return { error };
		}
	};

	private getJob = async (id: number): Promise<ApiResponse<Job>> => {
		try {
			const data = await this._auth.get<any, Job>(routes.JOB_ID(id));
			return { data };
		} catch (error) {
			return { error };
		}
	};

	private addJob = async (input: JobInput): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.post<JobInput, OK>(routes.JOB, input);
			return { data };
		} catch (error) {
			return { error };
		}
	};

	private updateJob = async (id: number, input: JobInput): Promise<ApiResponse<Job>> => {
		try {
			const data = await this._auth.put<JobInput, Job>(routes.JOB_ID(id), input);
			return { data };
		} catch (error) {
			return { error };
		}
	};

	private cancelJob = async (id: number): Promise<ApiResponse<OK>> => {
		try {
			const data = await this._auth.post<any, OK>(routes.JOB_ID(id));
			return { data };
		} catch (error) {
			return { error };
		}
	};
}

export default new Api();
