import React from 'react';
import { Typography, Image } from 'lib/components';
import { useWindowResize } from 'hooks/useWindowResize';
import { JobFile } from 'types';
import { useAppDispatch, actions } from 'store';
import classes from './index.module.scss';

interface Props {
	file: JobFile;
}

const FileDelete = ({ file }: Props) => {
	const { isSmallerDevice } = useWindowResize();

	const dispatch = useAppDispatch();

	return (
		<button
			className={`${classes['file-container']} ${classes['file-container-delete']}`}
			style={{
				width: '20rem',
				justifyContent: 'space-between',
			}}
			onClick={() => dispatch(actions.documentRemoved(file.id))}
		>
			<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="gray">
				{file.name}
			</Typography>
			<Image src="icTrashDanger" />
		</button>
	);
};

export default FileDelete;
