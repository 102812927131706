import React from 'react';
import Image, { ImageProps } from '../Image';

interface Props extends ImageProps {
	size: number | string;
}

const Avatar = ({ size, ...props }: Props) => {
	return <Image style={{ width: size, height: size, borderRadius: '50%', verticalAlign: 'middle' }} {...props} />;
};

export default Avatar;
