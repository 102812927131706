import React from 'react';
import { MoonLoader } from 'react-spinners';
import colors from 'res/colors';
import classes from './index.module.scss';

const LoadingScreen = () => {
	return (
		<div className={classes['loading-screen']}>
			<MoonLoader size={60} color={colors.tertiaryText} />
		</div>
	);
};

export default LoadingScreen;
