import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import reducer from './reducer';
import { RootState } from './types';

const middleware = [...getDefaultMiddleware<RootState>().concat(logger)];

export default configureStore({
	reducer,
	middleware,
	devTools: process.env.NODE_ENV === 'development',
});
