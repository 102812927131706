import React from 'react';
import { Color } from '../types';
import Typography from '../Typography';
import classes from './index.module.scss';

interface Props {
	children: React.ReactNode;
	variant: Color;
}

const Tag = ({ children, variant }: Props) => {
	return (
		<div className={`${classes['tag']} ${classes['tag-' + variant]}`}>
			<Typography color={variant}>{children}</Typography>
		</div>
	);
};

export default Tag;
