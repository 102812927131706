import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Employee } from 'types';
import { SelectOption } from 'lib/components/types';
import classes from './index.module.scss';

interface Props {
	employees: Employee[];
	setValue: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const PointOfContactSelect = ({ setValue, employees }: Props) => {
	const [options, setOptions] = useState<SelectOption<number>[]>([]);

	const [option, setOption] = useState<SelectOption<number> | null>();

	useEffect(() => {
		if (employees.length === 0) return;
		setOptions(() =>
			employees.map(({ id, name }) => ({
				value: id,
				label: name,
			}))
		);
	}, [employees]);

	const handleChange = (option: SelectOption<number> | null) => {
		setOption(option);
		setValue(option?.value);
	};

	return (
		<Select
			placeholder=""
			className={classes['react-select-container']}
			classNamePrefix="react-select"
			options={options}
			isClearable={true}
			isSearchable={true}
			value={option}
			onChange={handleChange}
		/>
	);
};

export default PointOfContactSelect;
