export default function useAddIdPrefix() {
	return function (id: number) {
		if (id <= 9) return `#00000${id}`;
		if (id >= 10 && id <= 99) return `#0000${id}`;
		if (id >= 100 && id <= 999) return `#000${id}`;
		if (id >= 1000 && id <= 9999) return `#00${id}`;
		if (id >= 10000 && id <= 99999) return `#0${id}`;
		else return `#${id}`;
	};
}
