import moment from 'moment';
import { Color } from 'lib/components/types';

export enum Roles {
	UNASSIGNED = 0,
	SUPER_ADMIN = 1,
	ADMIN = 2,
	MANAGER = 3,
}

export enum EmployeeType {
	FULL_TIME = 0,
	CONTRACTOR = 1,
}

export enum InventoryType {
	UNIT = 0,
	PACKAGE = 1,
	LIQUID = 2,
}

export enum InventoryUnitType {
	ML = 0,
	L = 1,
	G = 2,
	MG = 3,
}

export enum JobStatusType {
	PENDING = 0,
	ACCEPTED = 1,
	NURSE_ACCEPTED = 2,
	NURSE_REJECTED = 3,
	STARTED = 4,
	REACHED = 5,
	WORKING = 6,
	FINISHED = 7,
	CLOSED = 8,
	CANCELLED = 9,
	VOIDED = 10,
}

export const isExpired = (expiryAt: number) => moment.unix(expiryAt).isBefore(moment());

export const removeAuth = () => window.localStorage.removeItem('auth');

export const getStatusColor = (status: JobStatusType): Color => {
	if (status === JobStatusType.PENDING) {
		return 'warning';
	}

	if ([JobStatusType.NURSE_REJECTED, JobStatusType.CANCELLED].includes(status)) {
		return 'danger';
	}

	if ([JobStatusType.CLOSED].includes(status)) {
		return 'darkgrey';
	}

	return 'success';
};
