import React, { useCallback, useState } from 'react';
import ReactDom from 'react-dom';
import classes from './index.module.scss';
import DatePicker from 'react-datepicker';
import { Image, Typography } from '..';
import Button from '../Button';

interface BodyProps {
	selectedDate: Date | null;
	setSelectedDate: (date: Date) => any;
}

const CustomDatePickerBody = ({ selectedDate, setSelectedDate }: BodyProps) => {
	const onChange = (date: Date) => {
		setSelectedDate(date);
	};

	const customHeader = useCallback(
		({
			date,
			decreaseMonth,
			increaseMonth,
		}: {
			date: Date;
			decreaseMonth: () => any;
			increaseMonth: () => any;
		}) => (
			<div
				style={{
					margin: 10,
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<div onClick={decreaseMonth} className={classes['dpc-btn']}>
					<Image src="icChevronLeft" />
				</div>
				<Typography variant="body1">
					{
						[
							'January',
							'February',
							'March',
							'April',
							'May',
							'June',
							'July',
							'August',
							'September',
							'October',
							'November',
							'December',
						][(date as Date).getMonth()]
					}{' '}
					{(date as Date).getFullYear()}
				</Typography>
				<div onClick={increaseMonth} className={classes['dpc-btn']}>
					<Image src="icCheveronRight" />
				</div>
			</div>
		),
		[]
	);

	return (
		<DatePicker
			autoFocus
			selected={selectedDate}
			onChange={onChange}
			renderCustomHeader={customHeader}
			dateFormat="dd/MM/yyyy"
			calendarClassName={classes['dpc']}
			dayClassName={() => classes['dpc-dayName']}
			weekDayClassName={() => classes['dpc-weekName']}
			timeClassName={() => classes['dpc-timeName']}
			customInput={<input style={{ display: 'none' }} />}
			inline
		/>
	);
};

interface ModalProps {
	isOpen: boolean;
	onClose: () => any;
	onDateSet: (date: Date) => any;
}

const CustomSelectDatePickerModal = ({ isOpen, onClose, onDateSet }: ModalProps) => {
	const [selectedDate, setSelectedDate] = useState<Date | null>(null);

	if (!isOpen) {
		return null;
	}

	return ReactDom.createPortal(
		<div className={classes['modal']}>
			<div className={classes['modal-content']}>
				<div className={classes['modal-heading']}>
					<Typography>Pick Invoice Dates</Typography>
				</div>
				<div className={classes['modal-calendar']}>
					<CustomDatePickerBody selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
				</div>
				<div>
					{
						<Button
							variant="standard"
							color="primary"
							title="Select Date"
							style={{
								justifyContent: 'center',
								marginTop: '2rem',
								flexBasis: '50%',
							}}
							disabled={!selectedDate}
							onClick={() => {
								onDateSet(selectedDate!);
								onClose();
							}}
						/>
					}
				</div>
			</div>
		</div>,
		document.getElementById('portal') as HTMLElement
	);
};

export default CustomSelectDatePickerModal;
