import React, { useState } from 'react';
import classes from './index.module.scss';

interface Props {
	action: (requirement: string) => void;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddRequirementInput = ({ action, setIsOpen }: Props) => {
	const [requirement, setRequirement] = useState('');

	const addRequirement = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Escape') {
			setIsOpen(false);
		}

		if (e.key === 'Enter') {
			action(requirement);
			setRequirement('');
		}
	};

	return (
		<div className={classes['requirement-input-container']}>
			<input
				className={classes['requirement-input']}
				autoFocus
				value={requirement}
				onChange={(e) => setRequirement(e.target.value)}
				onBlur={() => setIsOpen(false)}
				onKeyDown={addRequirement}
			/>
		</div>
	);
};

export default AddRequirementInput;
