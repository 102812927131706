import React from 'react';
import classes from './index.module.scss';

interface Props {
	valuePercentage: number;
	variant?: 'primary' | 'warning' | 'danger';
}

const ProgressBar = ({ valuePercentage, variant = 'primary' }: Props) => {
	return (
		<div className={`${classes['progressBar']} ${classes['progressBar-' + variant]}`}>
			<div style={{ width: `${valuePercentage}%` }} className={classes['inner']}></div>
		</div>
	);
};

export default ProgressBar;
