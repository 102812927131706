import React, { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import classes from './index.module.scss';
import { ImageSource } from '../types';
import { Image, Typography } from '..';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useSelectTopNavState } from 'store/hooks';
import { actions, useSelectCurrentUser } from 'store';
import { useDispatch } from 'react-redux';

type SideNavItem = { to: string; icon: ImageSource; title: string };

const hospitalPortalSideNavItems: SideNavItem[] = [
	{
		to: '/dashboard',
		icon: 'icDashboard',
		title: 'Dashboard',
	},
	{
		to: '/jobs',
		icon: 'icHeadphones',
		title: 'Jobs',
	},
	{
		to: '/staff',
		icon: 'icStaff',
		title: 'Staff',
	},
];

const sideNavExtraItems: SideNavItem[] = [
	{
		to: '/settings',
		icon: 'icSettings',
		title: 'Settings',
	},
	{
		to: '/logout',
		icon: 'icLogout',
		title: 'Logout',
	},
];

const SideNavModal = () => {
	const { pathname } = useLocation();
	const { push } = useHistory();

	const dispatch = useDispatch();
	const { sidenavVisible } = useSelectTopNavState();
	const me = useSelectCurrentUser();

	useEffect(() => {
		closeMenu();
	}, [pathname]);

	const closeMenu = () => {
		dispatch(actions.sidenavToggled(false));
	};

	return (
		<AnimatePresence>
			{sidenavVisible && (
				<motion.div
					initial={{
						opacity: 0,
						translateX: '-60%',
					}}
					animate={{
						opacity: 1,
						translateX: '0%',
						transition: {
							duration: 0.5,
						},
					}}
					exit={{
						opacity: 0,
						translateX: '-60%',
					}}
					className={classes['sidenav-modal']}
				>
					<div className={classes['sidenav-container']}>
						<div className={classes['search-toolbar']}>
							<input placeholder="Search..." />
							<Image src="icSearch" />
						</div>
						<div className={classes['menu-items']}>
							<Typography
								variant="h6"
								style={{
									marginTop: '2rem',
									marginLeft: '0.5rem',
								}}
								color="darkgrey"
							>
								MENU
							</Typography>
							{hospitalPortalSideNavItems.map(({ icon, title, to }) => (
								<NavLink
									key={title}
									className={classes['menu-item']}
									activeClassName={classes['menu-item-active']}
									to={to}
								>
									<Image
										src={icon}
										style={{
											width: '2rem',
											height: '2rem',
										}}
									/>
									<p>{title}</p>
								</NavLink>
							))}
							<button className={classes['create-new-dispatch']} onClick={() => push('/jobs/create')}>
								<p>Add New Job</p>
								<div className={classes['plus-icon']}>+</div>
							</button>
							<Typography
								variant="h6"
								style={{
									marginTop: 'auto',
									marginLeft: '0.5rem',
								}}
								color="darkgrey"
							>
								EXTRA
							</Typography>
							{sideNavExtraItems.map(({ icon, title, to }) => (
								<NavLink
									key={title}
									className={classes['menu-item']}
									activeClassName={classes['menu-item-active']}
									to={to}
								>
									<Image
										src={icon}
										style={{
											width: '2rem',
											height: '2rem',
										}}
									/>
									<p>{title}</p>
								</NavLink>
							))}
							<div className={classes['admin-profile']}>
								<Image
									src={me.avatar ?? 'plAvatar'}
									style={{
										width: '4.4rem',
										height: '4.4rem',
										borderRadius: '50%',
										marginRight: '1rem',
									}}
								/>
								<div>
									<Typography variant="h4">{me.name}</Typography>
									<Typography
										variant="h4"
										weight="light"
										color="darkgrey"
										style={{ marginTop: '0.5rem' }}
									>
										Manager
									</Typography>
								</div>
							</div>
						</div>
					</div>
					<button className={classes['close-button']} onClick={closeMenu}>
						<Image src="icCancelBlack" />
					</button>
				</motion.div>
			)}
		</AnimatePresence>
	);
};

export default SideNavModal;
