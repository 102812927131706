import React from 'react';
import R from 'res/R';
import { LightVariantImage, DarkVariantImage, DefaultVariant, ImageSource } from 'lib/components/types';
import classes from './index.module.scss';

export type ImageProps = Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src' | 'srcSet'> & {
	className?: string;
	src: ImageSource;
};

const Image = ({ src, className, ...rest }: ImageProps) => {
	let image = '';

	if (!src.startsWith('http') && !src.startsWith('data')) {
		const [variant, filename] = src.split('.');
		if (variant === 'dark') {
			image = R.images.dark[filename as DarkVariantImage];
		} else if (variant === 'light') {
			image = R.images.light[filename as LightVariantImage];
		} else {
			image = R.images.default[src as DefaultVariant];
		}

		return (
			<img
				src={image}
				className={`${src === 'plAvatar' ? classes['image-avatar-border'] : ''} ${className}`}
				{...rest}
			/>
		);
	}

	return (
		<img
			src={src}
			className={`${src === 'plAvatar' ? classes['image-avatar-border'] : ''} ${className}`}
			{...rest}
		/>
	);
};

export default Image;
