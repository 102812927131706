import { Image, Typography } from 'lib/components';
import { RateType } from 'lib/util';
import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import classes from './index.module.scss';

type Variant = 'inline' | 'labelled';
interface Props extends React.InputHTMLAttributes<HTMLInputElement & HTMLTextAreaElement> {
	label: string;
	variant?: Variant;
	color?: 'primary' | 'danger';
	startIcon?: React.ReactNode;
	helperText?: string;
	rows?: number;
	datalist?: {
		id: string;
		options: string[];
	};
	textArea?: boolean;
	containerStyle?: CSSProperties;
	numberSymbol?: string[];
	onNumberIncrement?: () => any;
	onNumberDecrement?: () => any;
	defaultSymbolIndex?: RateType;
	onSymbolChange?: (index: RateType) => void;
}

const TextField = ({
	containerStyle,
	label,
	variant = 'inline',
	startIcon,
	color,
	className,
	datalist,
	textArea,
	rows,
	numberSymbol = ['$', '%'],
	helperText = '',
	defaultSymbolIndex = 0,
	onSymbolChange,
	...props
}: Props) => {
	const [currentNumberSymbolIndex, setCurrentNumberSymbolIndex] = useState(defaultSymbolIndex);
	const toggleSymbols = useCallback(
		(direction: 'up' | 'down') => {
			if (direction === 'up') {
				setCurrentNumberSymbolIndex((prev) => {
					if (prev >= numberSymbol.length - 1) {
						return 0;
					} else {
						return prev + 1;
					}
				});
			} else if (direction === 'down') {
				setCurrentNumberSymbolIndex((prev) => {
					if (prev === 0) {
						return numberSymbol.length - 1;
					} else {
						return prev - 1;
					}
				});
			}
		},
		[numberSymbol]
	);

	useEffect(() => {
		if (onSymbolChange) {
			onSymbolChange(currentNumberSymbolIndex);
		}
	}, [currentNumberSymbolIndex, onSymbolChange]);

	switch (variant) {
		case 'inline':
			return (
				<div className={classes['textFieldInline']} style={containerStyle}>
					<div className={classes['textFieldInline-content']}>
						<span className={classes['textFieldInline-startIcon']}>{startIcon}</span>
						<p className={classes['textFieldInline-label']}>{label}:</p>
						<input className={classes['textFieldInline-input']} {...props} />
					</div>
				</div>
			);

		case 'labelled':
			const colorClass = color ? classes['textFieldLabelled-color-' + color] : '';
			let inputClasses = classes['textFieldLabelled-input'];
			if (className) {
				inputClasses += ' ' + className;
			}
			return (
				<div className={`${classes['textFieldLabelled']} ${colorClass}`} style={containerStyle}>
					<label className={classes['textFieldLabelled-label']}>{label}</label>
					<div style={{ position: 'relative' }}>
						{!textArea ? (
							<input className={inputClasses} {...props} list={datalist?.id} />
						) : (
							<textarea rows={rows} className={inputClasses} {...props} />
						)}
						{props.type === 'number' && (
							<div className={classes['textFieldLabelled-input-numberToggle']}>
								<span onClick={() => toggleSymbols('up')}>
									<Image src="icDownCaretSm" />
								</span>
								<Typography>{numberSymbol[currentNumberSymbolIndex]}</Typography>
								<span onClick={() => toggleSymbols('down')}>
									<Image src="icDownCaretSm" />
								</span>
							</div>
						)}
					</div>
					{helperText && <p className={classes['textFieldLabelled-input-helperText']}>{helperText}</p>}
					{datalist && (
						<datalist id={datalist.id}>
							{datalist.options.map((option) => (
								<option key={option} value={option} />
							))}
						</datalist>
					)}
					{datalist && <Image src="icDownCaret" className={classes['textFieldLabelled-caret']} />}
				</div>
			);

		default:
			return (
				<div className={classes['textFieldInline']} style={containerStyle}>
					<div className={classes['textFieldInline-content']}>
						<span className={classes['textFieldInline-startIcon']}>{startIcon}</span>
						<p className={classes['textFieldInline-label']}>{label}:</p>
						<input className={classes['textFieldInline-input']} {...props} />
					</div>
				</div>
			);
	}
};

export default TextField;
