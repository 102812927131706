import React, { useState } from 'react';
import classes from './index.module.scss';
import { Image } from 'lib/components';
import Typography from 'lib/components/Typography';

interface Props {}

const Notification = ({}: Props) => {
	const [showDropDown, setShowDropDown] = useState(false);
	return (
		<>
			<a onClick={() => setShowDropDown((prev) => !prev)} className={classes['topNav-notification']}>
				<Image src={'icBellPrimary'} />
				{showDropDown && (
					<div className={classes['topNav-notification-dropdown']}>
						<div className={classes['topNav-notification-dropdown-heading']}>
							<Typography weight="medium">Notifications</Typography>
							<div className={classes['topNav-notification-dropdown-heading-right']}>
								<Typography>Mark all as read</Typography>
							</div>
						</div>
						<div className={classes['topNav-notification-content']}>
							<div className={classes['topNav-notification-item']}>
								<Typography weight="light">
									Your Job Request #4883290 has been accepted by the admins.
								</Typography>
								<Typography className={classes['topNav-notification-item-sub']}>5 mins ago</Typography>
							</div>
							<div className={classes['topNav-notification-item']}>
								<Typography weight="light">
									Your Job Request #4883290 has been accepted by the admins.
								</Typography>
								<Typography className={classes['topNav-notification-item-sub']}>5 mins ago</Typography>
							</div>
							<div className={classes['topNav-notification-item']}>
								<Typography weight="light">
									Your Job Request #4883290 has been accepted by the admins.
								</Typography>
								<Typography className={classes['topNav-notification-item-sub']}>5 mins ago</Typography>
							</div>
							<div className={classes['topNav-notification-item']}>
								<Typography weight="light">
									Your Job Request #4883290 has been accepted by the admins.
								</Typography>
								<Typography className={classes['topNav-notification-item-sub']}>5 mins ago</Typography>
							</div>
							<div className={classes['topNav-notification-item']}>
								<Typography weight="light">
									Your Job Request #4883290 has been accepted by the admins.
								</Typography>
								<Typography className={classes['topNav-notification-item-sub']}>5 mins ago</Typography>
							</div>
						</div>
					</div>
				)}
			</a>
		</>
	);
};

export default Notification;
