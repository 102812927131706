import React from 'react';
import classes from './index.module.scss';

interface Props {
	children: React.ReactNode;
	className?: string;
}

const PageWrapper = ({ className = '', children }: Props) => {
	const combinedClassNames = `${classes['pageWrapper']} ${className}`;
	return <div className={combinedClassNames}>{children}</div>;
};

export default PageWrapper;
