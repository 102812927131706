import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import moment from 'moment';
import { Employee, EmployeeCreateInput, EmployeeUpdateInput } from 'types';
import Api from 'lib/api';
import routes from 'lib/api/routes';
import { AppThunk, actions, SliceState } from '..';

const initialState: SliceState<Employee> = {
	updatedAt: moment.utc().valueOf(),
	list: [],
};

export default createSlice({
	name: 'employees',
	initialState,
	reducers: {
		employeesFetched: (state, { payload }: PA<Employee[]>) => {
			state.updatedAt = moment.utc().valueOf();
			state.list = payload;
		},
		employeeFetched: (state, { payload }: PA<Employee>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload.id);

			if (index !== -1) {
				list.splice(index, 1);
			}

			list.push({
				...payload,
			});

			state.updatedAt = moment.utc().valueOf();
			state.list = list;
		},
		employeeDeleted: (state, { payload }: PA<number>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => id === payload);

			if (index !== -1) {
				list.splice(index, 1);
			}

			state.updatedAt = moment.utc().valueOf();
			state.list = list;
		},
	},
});

const fetchEmployees = (): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.EMPLOYEE, method: 'GET' }));

		const { data, error } = await Api.employees.getAll();

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.EMPLOYEE,
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.EMPLOYEE,
					method: 'GET',
					message: 'Employees fetched successfully',
					payload: {},
				})
			);
			dispatch(actions.employeesFetched(data!));
		}
	};
};

const fetchEmployee = (id: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.EMPLOYEE_ID(id), method: 'GET' }));

		const { data, error } = await Api.employees.getOne(id);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.EMPLOYEE_ID(id),
					method: 'GET',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.employeeFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.EMPLOYEE_ID(id),
					method: 'GET',
					message: 'Employee fetched successfully',
					payload: {},
				})
			);
		}
	};
};

const addEmployee = (input: EmployeeCreateInput): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.EMPLOYEE, method: 'POST' }));

		const { data, error } = await Api.employees.create(input);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.EMPLOYEE,
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.EMPLOYEE,
					method: 'POST',
					message: 'Employee added successfully',
					payload: { ...data },
				})
			);
			dispatch(actions.fetchEmployees());
		}
	};
};

const updateEmployee = (employeeId: number, input: EmployeeUpdateInput): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.EMPLOYEE_ID(employeeId), method: 'PUT' }));

		const { data, error } = await Api.employees.update(employeeId, input);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.EMPLOYEE_ID(employeeId),
					method: 'PUT',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.employeeFetched(data!));
			dispatch(
				actions.requestFinished({
					name: routes.EMPLOYEE_ID(employeeId),
					method: 'PUT',
					message: 'Employee updated successfully',
					payload: {},
				})
			);
		}
	};
};

const updateEmployeeAvatar = (id: number, file: File): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.UPLOAD_ID(id), method: 'POST' }));

		const { error } = await Api.avatar.upload(id, file);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.UPLOAD_ID(id),
					method: 'POST',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(
				actions.requestFinished({
					name: routes.UPLOAD_ID(id),
					method: 'POST',
					message: 'Avatar uploaded successfully',
					payload: {},
				})
			);
			dispatch(actions.fetchEmployee(id));
		}
	};
};

const deleteEmployee = (employeeId: number): AppThunk => {
	return async (dispatch) => {
		dispatch(actions.requestStarted({ name: routes.EMPLOYEE_ID(employeeId), method: 'DELETE' }));

		const { data, error } = await Api.employees.delete(employeeId);

		if (error) {
			dispatch(
				actions.requestFailed({
					name: routes.EMPLOYEE_ID(employeeId),
					method: 'DELETE',
					message: error.message,
					payload: { ...error },
				})
			);
		} else {
			dispatch(actions.employeeDeleted(employeeId));
			dispatch(
				actions.requestFinished({
					name: routes.EMPLOYEE_ID(employeeId),
					method: 'DELETE',
					message: 'Employee deleted successfully',
					payload: { ...data },
				})
			);
		}
	};
};

export const employeeThunks = {
	fetchEmployees,
	fetchEmployee,
	addEmployee,
	updateEmployee,
	updateEmployeeAvatar,
	deleteEmployee,
};
