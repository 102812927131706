import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import SideNav from '../SideNav';
import classes from './index.module.scss';

interface Props {
	children: React.ReactNode;
	noPadding?: boolean;
}

const PageLayout = ({ noPadding, children }: Props) => {
	const mainAreaRef = useRef<HTMLDivElement>(null);
	const pathName = useLocation();

	useEffect(() => {
		if (mainAreaRef.current) {
			mainAreaRef.current.scrollTo(0, 0);
		}
	}, [pathName]);

	return (
		<div className={classes['layout']}>
			<SideNav />
			<div
				className={classes['mainArea']}
				style={{
					padding: noPadding ? 0 : undefined,
				}}
				ref={mainAreaRef}
			>
				{children}
			</div>
		</div>
	);
};

export default PageLayout;
