import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import useComprehensiveTopNav from 'hooks/useComprehensiveTopNav';
// import { useWindowResize } from 'hooks/useWindowResize';
import { Typography, Button } from 'lib/components';
import { TextField } from 'lib/components/FormFields';
import { useAppDispatch, actions, useSelectRequest } from 'store';
import routes from 'lib/api/routes';
import useCustomToast from 'hooks/toast';
import { Timing } from 'lib/util';
import classes from './index.module.scss';
import CustomSelectDatePickerModal from 'lib/components/CustomSelectDatePickerModal';

const EmployeeCreateScreen = () => {
	const toast = useCustomToast();
	const { push } = useHistory();
	// const { isSmallDevice, isSmallerDevice } = useWindowResize();

	const dispatch = useAppDispatch();
	const { active } = useSelectRequest();

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [password, setPassword] = useState('');
	const [dateOfBirth, setDateOfBirth] = useState<Date>(new Date());
	const [showDOBModal, setShowDOBModal] = useState(false);

	const openDOBModal = useCallback(() => {
		if (showDOBModal === false) {
			setShowDOBModal(true);
		}
	}, [showDOBModal, setShowDOBModal]);

	const closeDOBModal = () => setShowDOBModal(false);

	useEffect(() => {
		if (active.status === 'idle') {
			return;
		}

		if (active.name === routes.EMPLOYEE && active.method === 'POST') {
			if (active.status === 'failed') {
				toast(active.message, 'error');
			} else if (active.status === 'succeeded') {
				toast(active.message);
				push(`/staff`);
			}
		}
	}, [active]);

	useComprehensiveTopNav({
		contentHeader: 'New Staff',
		showSearchBar: false,
	});

	const handleCreate = () => {
		dispatch(
			actions.addEmployee({
				name,
				email,
				phoneNumber,
				dateOfBirth: Timing.formatDate(dateOfBirth),
				password,
			})
		);
	};

	return (
		<>
			<CustomSelectDatePickerModal
				isOpen={showDOBModal}
				onClose={closeDOBModal}
				onDateSet={(date) => setDateOfBirth(date)}
			/>
			<div
				className={classes['profile-container']}
				style={{
					flexDirection: 'column',
					padding: '2rem 3rem',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Typography weight="bold">Basic info:</Typography>
					<div className={classes['row'] + ' ' + classes['textFields']}>
						<TextField
							placeholder="Rex Tyler"
							label="Name:"
							variant="labelled"
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
						<TextField
							placeholder="+233 209 050 400"
							label="Mobile number:"
							variant="labelled"
							value={phoneNumber}
							onChange={(e) => setPhoneNumber(e.target.value)}
						/>
						<TextField
							placeholder="rex@tyler.com"
							label="Email:"
							variant="labelled"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
						<TextField
							label="Date of Birth"
							variant="labelled"
							onFocus={openDOBModal}
							onClick={openDOBModal}
							value={dateOfBirth.toDateString()}
						/>
					</div>
					<div className={classes['row']} style={{ marginTop: '1.5rem' }}>
						<TextField
							placeholder="***********"
							label="Password:"
							type="password"
							variant="labelled"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</div>
					<div className={classes['footButtons']}>
						<Button variant="standard" color="primary" title="Add Employee" onClick={handleCreate} />
						<Button variant="standard" color="primary" title="Cancel" onClick={() => push(`/staff`)} />
					</div>
				</div>
			</div>
		</>
	);
};

export default EmployeeCreateScreen;
