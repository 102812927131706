const PORTAL = 'portal';
const HOSPITAL = 'hospital';

export default {
	UPLOAD_ID: (id: number) => `${PORTAL}/upload/${id}`,
	UPLOAD_DOCUMENT: `${PORTAL}/upload-document-hospital`,
	AUTH_CALLBACK: `${PORTAL}/auth/callback`,
	SIGN_OUT: `${PORTAL}/sign-out`,
	USER: `${HOSPITAL}/user`,
	USER_ID: (id: number) => `${HOSPITAL}/user/${id}`,
	EMPLOYEE: `${HOSPITAL}/employee`,
	EMPLOYEE_ID: (id: number) => `${HOSPITAL}/employee/${id}`,
	EMPLOYEE_SUSPEND: (id: number) => `${HOSPITAL}/employee/${id}/suspend`,
	JOB: `${HOSPITAL}/job`,
	JOB_ID: (id: number) => `${HOSPITAL}/job/${id}`,
};
