import { Job } from 'types';
import { Sort } from './types';

export const jobStatusCompareFn = (a: Job, b: Job, sort: Sort) => {
	// if (a.stock > b.stock) {
	// 	return sort === 'asc' ? 1 : -1;
	// } else if (a.stock < b.stock) {
	// 	return sort === 'asc' ? -1 : 1;
	// }
	return 0;
};

export const jobIdCompareFn = (a: Job, b: Job, sort: Sort) => {
	console.log('jobid compareFn');
	if (a.id > b.id) {
		return sort === 'asc' ? 1 : -1;
	} else if (a.id < b.id) {
		return sort === 'asc' ? -1 : 1;
	}
	return 0;
};

export const jobInvoiceIdCompareFn = (a: Job, b: Job, sort: Sort) => {
	const invoiceA = a.invoice;
	const invoiceB = b.invoice;

	if (!invoiceA || !invoiceB) {
		return 0;
	}

	if (invoiceA.id > invoiceB.id) {
		return sort === 'asc' ? 1 : -1;
	} else if (invoiceA.id < invoiceB.id) {
		return sort === 'asc' ? -1 : 1;
	}
	return 0;
};

export const jobCreatedByCompareFn = (a: Job, b: Job, sort: Sort) => {
	if (sort === 'asc') {
		return a.creator.name.localeCompare(b.creator.name) === -1 ? 1 : -1;
	} else {
		return a.creator.name.localeCompare(b.creator.name) === 1 ? -1 : 1;
	}
};

export const jobTimeCompareFn = (a: Job, b: Job, sort: Sort) => {
	const valueA = new Date(a.createdAt);
	const valueB = new Date(b.createdAt);
	if (valueA > valueB) {
		return sort === 'asc' ? 1 : -1;
	} else if (valueA < valueB) {
		return sort === 'asc' ? -1 : 1;
	}
	return 0;
};

export const jobNurseCompareFn = (a: Job, b: Job, sort: Sort) => {
	const nurseA = a.nurse;
	const nurseB = b.nurse;
	if (!nurseA || !nurseB) {
		return 0;
	}
	if (sort === 'asc') {
		return nurseA.user.name.localeCompare(nurseB.user.name) === -1 ? 1 : -1;
	} else {
		return nurseA.user.name.localeCompare(nurseB.user.name) === 1 ? -1 : 1;
	}
};
