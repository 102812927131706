import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from 'lib/components';
import Avatar from 'lib/components/Avatar';
import useAddIdPrefix from 'hooks/useAddIdPrefix';
import { useSelectCurrentUser } from 'store';
import { Employee } from 'types';
import classes from './index.module.scss';

interface Props {
	staff: Employee;
}

const StaffRow = ({ staff }: Props) => {
	const addPrefix = useAddIdPrefix();
	const { push } = useHistory();
	const me = useSelectCurrentUser();

	const toEmployeeEdit = useCallback(() => {
		if (staff.id !== me.id) push(`/staff/view/${staff.id}`);
		else push(`/profile`);
	}, [me]);

	return (
		<div className={classes['table-row']} onClick={toEmployeeEdit}>
			<div className={classes['col']}>
				<Typography weight="medium" variant={'h6'}>
					{addPrefix(staff.id)}
				</Typography>
			</div>
			<div className={classes['col']}>
				<Avatar src={staff.avatar ?? 'plAvatar'} size={32} />
			</div>
			<div className={classes['col']}>
				<Typography weight="medium" variant={'h6'}>
					{staff.name}
				</Typography>
			</div>
			<div className={classes['col']}>
				<Typography weight="medium" variant={'h6'}>
					{staff.email}
				</Typography>
			</div>
			<div className={classes['col']}>
				<Typography weight="medium" variant={'h6'}>
					{staff.phoneNumber}
				</Typography>
			</div>
		</div>
	);
};

export default StaffRow;
