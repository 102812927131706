import React from 'react';
import { useAppDispatch, actions } from 'store';
import { User } from 'types';

interface Props {
	showMapToggle?: boolean;
	showSearchBar?: boolean;
	showNotification?: boolean;
	showSidenav?: boolean;
	contentHeader?: string;
	user?: User;
}

const useComprehensiveTopNav = ({
	showMapToggle = false,
	showSearchBar = false,
	showNotification = true,
	showSidenav = false,
	contentHeader = '',
}: Props) => {
	const dispatch = useAppDispatch();

	React.useEffect(() => {
		dispatch(actions.mapDisplayToggled(showMapToggle));
		dispatch(actions.searchBarDisplayToggled(showSearchBar));
		dispatch(actions.notificationToggled(showNotification));
		dispatch(actions.sidenavToggled(showSidenav));
		dispatch(actions.contentHeaderChanged(contentHeader));
		// dispatch(actions.userFetched(user));
	}, []);
};

export default useComprehensiveTopNav;
