import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useComprehensiveTopNav from 'hooks/useComprehensiveTopNav';
import { Button, Typography, Image, LoadingScreen, SearchBar, Pagination } from 'lib/components';
import JobsDataTable from 'lib/components/DataTables/Jobs';
import { useAppDispatch, actions, useSelectJobs, useSelectRequest } from 'store';
import routes from 'lib/api/routes';
import useCustomToast from 'hooks/toast';
import { Job } from 'types';
import classes from './index.module.scss';
import { JobStatusType } from 'lib/util';

type FilterItem<T = any> = { label: string; value: T };
const JOB_STATUS_FILTERS: FilterItem<JobStatusType>[] = [
	{ label: 'Pending', value: JobStatusType.PENDING },
	{ label: 'Approved', value: JobStatusType.ACCEPTED },
	{ label: 'Rejected', value: JobStatusType.NURSE_REJECTED },
];

const JobsScreen = () => {
	const toast = useCustomToast();
	const { push } = useHistory();
	const { search } = useLocation();
	const filter = new URLSearchParams(search).get('filter');

	const dispatch = useAppDispatch();
	const { active } = useSelectRequest();
	const jobs = useSelectJobs();

	const [isDataFetched, setIsDataFetched] = useState(false);
	const [checked, setChecked] = useState<number[]>([]);
	const [checkedJob, setCheckedJob] = useState<Job>();
	const [paginatedJobs, setPaginatedJobs] = useState<Job[]>([]);
	const [filteredJobs, setFilteredJobs] = useState<Job[]>([]);
	const [currentFilters, setCurrentFilters] = useState<Record<JobStatusType, boolean>>(
		JOB_STATUS_FILTERS.reduce((acc, cur) => {
			if (filter) {
				if (filter === 'pending' && cur.label === 'Pending') {
					acc[cur.value] = true;
				} else if (filter === 'approved' && cur.label === 'Approved') {
					acc[cur.value] = true;
				}
			} else {
				acc[cur.value] = false;
			}
			return acc;
		}, {} as Record<JobStatusType, boolean>)
	);

	useEffect(() => {
		const filters = Object.entries(currentFilters).filter((item) => item[1] !== false);
		if (filters.length === 0) {
			setFilteredJobs(jobs.list);
			return;
		}

		const filtered: Job[] = [];
		for (const job of jobs.list) {
			if (currentFilters[job.status]) {
				filtered.push(job);
			}
		}

		setFilteredJobs(filtered);
	}, [currentFilters, jobs.list]);

	useEffect(() => {
		dispatch(actions.fetchJobs());
	}, []);

	useEffect(() => {
		setIsDataFetched(true);
	}, [jobs.updatedAt]);

	useEffect(() => {
		if (checked.length === 0) return;

		const job = jobs.list.find((job) => job.id === checked[0]);
		setCheckedJob(job);
	}, [checked]);

	const handleFilterClick = useCallback(
		(filterItem: FilterItem<JobStatusType>) => {
			setCurrentFilters((filters) => {
				let currentFilterValue = filters[filterItem.value];
				return {
					...filters,
					[filterItem.value]: !currentFilterValue,
				};
			});
		},
		[setCurrentFilters]
	);

	const handleCheckAction = (id: number, isChecked: boolean) => {
		if (isChecked && !checked.includes(id)) {
			setChecked([id]);
			return;
		}

		if (!isChecked || checked.includes(id)) {
			setChecked([]);
		}
	};

	const cancelJob = (id: number) => {
		dispatch(actions.cancelJob(id));
	};

	useEffect(() => {
		if (active.status === 'idle') {
			return;
		}

		if (active.name === routes.JOB_ID(checked[0]) && active.method === 'POST') {
			if (active.status === 'failed') {
				toast(active.message, 'error');
			} else if (active.status === 'succeeded') {
				toast(active.message);
				setCheckedJob(undefined);
				setChecked([]);
			}
		}
	}, [active]);

	useComprehensiveTopNav({
		contentHeader: 'Your Jobs',
		showMapToggle: true,
		showSearchBar: false,
	});

	if (!isDataFetched) return <LoadingScreen />;

	return (
		<div className={classes['dispatches-container']}>
			<div className={classes['row']}>
				<SearchBar
					containerStyle={{ maxWidth: '20rem' }}
					onSearch={(value) => console.log({ value })}
					className={classes['search-input-container']}
					placeholder="Search..."
				/>
				{JOB_STATUS_FILTERS.map((filterItem, idx, arr) => (
					<Button
						onClick={() => handleFilterClick(filterItem)}
						key={filterItem.value}
						title={filterItem.label}
						className={`${classes['button']}`}
						color="dark"
						style={{
							marginRight: idx === arr.length - 1 ? '1rem' : 'initial',
						}}
						isActivePrimary={currentFilters && currentFilters[filterItem.value]}
					/>
				))}
				<button className={classes['row']} onClick={() => push('/jobs/create')}>
					<Typography weight="medium">Add New Job</Typography>
					<div className={classes['plus-icon']}>+</div>
				</button>
			</div>

			{checked.length !== 0 && (
				<div className={classes['actions']}>
					<a className={classes['actions-button-primary']} onClick={() => push(`/jobs/view/${checked[0]}`)}>
						<Image src="icExportBlack" />
						<Typography weight="regular">View</Typography>
					</a>
					{checkedJob && checkedJob.status === 0 && (
						<a className={classes['actions-button']} onClick={() => cancelJob(checked[0])}>
							<Image src="icTrashDanger" />
							<Typography weight="regular">Cancel</Typography>
						</a>
					)}
				</div>
			)}

			<div
				className={classes['dispatches-list']}
				style={{
					paddingTop: '1rem',
				}}
			>
				<JobsDataTable jobs={paginatedJobs} checkAction={handleCheckAction} checkedId={checked[0] ?? -1} />
			</div>
			<Pagination
				items={filteredJobs}
				onChangePage={(pageItems: Job[]) => setPaginatedJobs(pageItems)}
				itemsPerPage={12}
			/>
		</div>
	);
};

export default JobsScreen;
