import React from 'react';
import { Typography, Image } from 'lib/components';
import { Job } from 'types';
import { JobStatus, getStatusColor } from 'lib/util';
import classes from './index.module.scss';
import Tag from 'lib/components/Tag';
import useAddIdPrefix from 'hooks/useAddIdPrefix';
import { useHistory } from 'react-router-dom';

interface Props {
	job: Job;
	checkedId: number;
	checkAction: (id: number, isChecked: boolean) => void;
}

const JobRow = ({ job }: Props) => {
	const addPrefix = useAddIdPrefix();
	const { push } = useHistory();

	return (
		<div className={classes['table-row']} onClick={() => push(`/jobs/view/${job.id}`)}>
			<div className={classes['col']}>
				<Tag variant={getStatusColor(job.status)}>{JobStatus.text(job.status)}</Tag>
			</div>
			<div className={classes['col']}>
				<Typography weight="medium" variant={'h6'}>
					{addPrefix(job.id)}
				</Typography>
			</div>
			<div className={classes['col']}>
				<Typography weight="medium" variant={'h6'}>
					#654875
				</Typography>
			</div>
			<div className={classes['col']}>
				<div className={classes['col-createdBy']}>
					<Image src="icUser" />
					<Typography weight="regular" variant={'h6'}>
						{job.creator.name}
					</Typography>
				</div>
			</div>
			<div className={classes['col']}>
				<div className={classes['col-assignedNurse']}>
					<Image src="icUserNurse" />
					<Typography weight="regular" variant={'h6'}>
						{job.nurse?.user?.name ?? '-'}
					</Typography>
				</div>
			</div>

			<div className={classes['col']}>
				<div className={classes['col-startTime']}>
					<Image src="icClockSm" />
					<Typography weight="regular" variant={'h6'}>
						{job.startingAt}
					</Typography>
				</div>
			</div>

			<div className={classes['col']}>
				<div className={classes['col-endTime']}>
					<Image src="icClockSm" />
					<Typography weight="regular" variant={'h6'}>
						-
					</Typography>
				</div>
			</div>
		</div>
	);
};

export default JobRow;
