import React, { useState, useEffect, useRef } from 'react';
import useComprehensiveTopNav from 'hooks/useComprehensiveTopNav';
import { useWindowResize } from 'hooks/useWindowResize';
import { Typography, Image } from 'lib/components';
import { useAppDispatch, useSelectCurrentUser, actions, useSelectRequest } from 'store';
import routes from 'lib/api/routes';
import useCustomToast from 'hooks/toast';
import classes from './index.module.scss';
import { useAuthentication } from 'hooks/useLocalStorage';
import selectImageHandler from 'lib/util/selectImageHandler';

const ProfileScreen = () => {
	const toast = useCustomToast();
	const [auth, setAuth] = useAuthentication();
	const { isSmallDevice, isSmallerDevice } = useWindowResize();

	const dispatch = useAppDispatch();
	const { active } = useSelectRequest();
	const user = useSelectCurrentUser();

	const [name, setName] = useState(user.name);
	const [email, setEmail] = useState(user.email);
	const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber ?? '');
	const [newPassword, setNewPassword] = useState('');
	const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
	const [profileImageString, setProfileImageString] = useState<any>('plAvatar');

	useEffect(() => {
		if (active.status === 'idle') {
			return;
		}

		if (active.name === routes.USER && active.method === 'PUT') {
			if (active.status === 'failed') {
				toast(active.message, 'error');
			} else if (active.status === 'succeeded') {
				toast(active.message);
			}
		}

		if (active.name === routes.UPLOAD_ID(user.id) && active.method === 'POST') {
			if (active.status === 'failed') {
				toast(active.message, 'error');
			} else if (active.status === 'succeeded') {
				toast(active.message);
			}
		}
	}, [active]);

	useEffect(() => {
		setAuth({ ...auth!, user });
	}, [user]);

	useComprehensiveTopNav({
		contentHeader: `${user.name} - Profile`,
		showSearchBar: false,
	});

	const handleUpdate = () => {
		dispatch(
			actions.updateCurrentUser({
				name,
				email,
				phoneNumber,
				newPassword,
				newPasswordConfirmation,
			})
		);
	};

	const profileImageRef = useRef<HTMLInputElement>(null);

	const handleFileSelect = selectImageHandler((file, fileURL) => {
		setProfileImageString(fileURL);
		dispatch(actions.updateCurrentUserAvatar(file));
	});

	return (
		<div
			className={classes['profile-container']}
			style={{
				flexDirection: 'column',
			}}
		>
			<>
				<div className={classes['top-container']}>
					<div className={classes['image-area']}>
						<div
							className={classes['profile-image-container']}
							style={{
								margin: '2rem',
								border: 'none',
							}}
						>
							<div className={classes['small-circle']}>
								<div
									onClick={() => profileImageRef.current?.click()}
									className={classes['inner-circle']}
									style={{ borderColor: '#0CB9C3' }}
								>
									<Image
										src={'icImageUpload'}
										style={{ width: 12, height: 12, objectFit: 'contain' }}
									/>
									<input
										style={{ display: 'none' }}
										onChange={handleFileSelect}
										ref={profileImageRef}
										type="file"
										id="profileImage"
										name="profileImage"
										accept="image/*"
									/>
								</div>
							</div>
							<Image src={user.avatar ?? profileImageString} style={{ width: '100%', height: '100%' }} />
						</div>
					</div>
					<div className={classes['profile-details']}>
						<div style={{ margin: '0rem 1rem 1rem 0' }}>
							<Typography color="darkgrey" variant={isSmallerDevice ? 'h5' : 'subheading2'}>
								Name:
							</Typography>
							<input
								className={classes['input']}
								style={{
									width: '15rem',
									minWidth: 250,
								}}
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
						</div>
					</div>
				</div>
				<div
					className={classes['bottom-container']}
					style={{
						padding: isSmallDevice ? '1rem' : undefined,
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Typography weight="bold">Basic info:</Typography>
						<div className={classes['row']}>
							<div style={{ margin: '1rem 1rem 1rem 0' }}>
								<Typography color="darkgrey" variant={isSmallerDevice ? 'h5' : 'subheading2'}>
									Mobile number:
								</Typography>
								<input
									className={`${classes['input']} ${classes['palewhite']}`}
									style={{
										width: '15rem',
									}}
									value={phoneNumber}
									onChange={(e) => setPhoneNumber(e.target.value)}
								/>
							</div>
							<div style={{ margin: '1rem 1rem 1rem 0' }}>
								<Typography color="darkgrey" variant={isSmallerDevice ? 'h5' : 'subheading2'}>
									Email:
								</Typography>
								<input
									className={`${classes['input']} ${classes['palewhite']}`}
									style={{
										width: '15rem',
										minWidth: 180,
									}}
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
						</div>
						<div className={classes['row']} style={{ marginTop: '1.5rem' }}>
							<div style={{ margin: '0rem 1rem 1rem 0' }}>
								<Typography color="darkgrey" variant={isSmallerDevice ? 'h5' : 'subheading2'}>
									Password:
								</Typography>
								<input
									className={`${classes['input']} ${classes['palewhite']}`}
									style={{
										width: '15rem',
										minWidth: 200,
									}}
									placeholder="***********"
									type="password"
									value={newPassword}
									onChange={(e) => setNewPassword(e.target.value)}
								/>
							</div>
							<div style={{ margin: '0rem 1rem 1rem 0' }}>
								<Typography color="darkgrey" variant={isSmallerDevice ? 'h5' : 'subheading2'}>
									Confirm Password:
								</Typography>
								<input
									className={`${classes['input']} ${classes['palewhite']}`}
									style={{
										width: '15rem',
										minWidth: 200,
									}}
									placeholder="***********"
									type="password"
									value={newPasswordConfirmation}
									onChange={(e) => setNewPasswordConfirmation(e.target.value)}
								/>
							</div>
						</div>
					</div>
				</div>
				<div
					className={classes['row']}
					style={{
						margin: 'auto 0 1rem 1rem',
					}}
				>
					<button
						className={classes['button']}
						style={{
							width: 'auto',
							marginRight: '1rem',
						}}
						onClick={handleUpdate}
					>
						<p>Update Profile</p>
					</button>
					<button
						className={`${classes['button']} ${classes['palewhite']}`}
						style={{
							width: 'auto',
						}}
					>
						<p>Cancel</p>
					</button>
				</div>
			</>
		</div>
	);
};

export default ProfileScreen;
