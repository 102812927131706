import React, { useRef } from 'react';
import useComprehensiveTopNav from 'hooks/useComprehensiveTopNav';
import { Typography } from 'lib/components';
import classes from './index.module.scss';
import Image from 'lib/components/Image';
import { Scrollbars } from 'react-custom-scrollbars';

// const DispatchScreen = () => {
// 	useComprehensiveTopNav({
// 		contentHeader: 'Jobs',
// 		showMapToggle: true,
// 		showSearchBar: true,
// 	});

// 	return (
// 		<PageWrapper>
// 			<PageLayout noPadding>
// 				<DispatchScreenView />
// 			</PageLayout>
// 		</PageWrapper>
// 	);
// };

const ratings = [
	{ id: '0', src: 'icStarGold' },
	{ id: '1', src: 'icStarGold' },
	{ id: '2', src: 'icStarGold' },
	{ id: '3', src: 'icStarGold' },
	{ id: '4', src: 'icStarGrey' },
];

const timeline = [
	{ id: '0', time: '08/03/21 - 07:50AM', description: 'Hospital Submitted Dispatch', location: 'NYC, 123th Street' },
	{
		id: '1',
		time: '08/03/21 - 10:50AM',
		description: 'Nurse Accepted Dispatch',
		location: 'NYC, 23th Joogalo Street',
	},
	{ id: '2', time: '08/03/21 - 07:50AM', description: 'Hospital Submitted Dispatch', location: 'NYC, 123th Street' },
	{
		id: '3',
		time: '08/03/21 - 10:50AM',
		description: 'Nurse Accepted Dispatch',
		location: 'NYC, 23th Joogalo Street',
	},
];

const DispatchScreen = () => {
	const containerRef = useRef<HTMLDivElement>(null);
	useComprehensiveTopNav({
		contentHeader: 'Jobs',
		showMapToggle: true,
		showSearchBar: true,
	});
	return (
		<div className={classes['dispatch-view-container']} ref={containerRef}>
			<div
				style={{
					padding: '1.5rem 3rem 0.75rem 3rem',
				}}
			>
				<div
					className={classes['row']}
					style={{
						justifyContent: 'space-between',
					}}
				>
					<div className={classes['row']}>
						<div
							className={classes['badge']}
							style={{
								marginTop: '0.5rem',
							}}
						>
							<Typography>
								Order Number:{' '}
								<span
									style={{
										fontWeight: 600,
									}}
								>
									#001256
								</span>
							</Typography>
						</div>
						<div
							className={classes['row']}
							style={{
								marginLeft: '1rem',
								marginTop: '0.5rem',
							}}
						>
							<Image src="icClockPrimary" />
							<Typography
								style={{
									marginLeft: '0.5rem',
								}}
							>
								Dispatch Submitted at : 08/08/21 - 07:33AM
							</Typography>
						</div>
						<div
							className={classes['row']}
							style={{
								marginLeft: '1rem',
								marginTop: '0.5rem',
							}}
						>
							<Image src="icClockPrimary" />
							<Typography
								style={{
									marginLeft: '0.5rem',
								}}
							>
								Dispatch Ended at : 08/08/21 - 07:33PM
							</Typography>
						</div>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-end',
						}}
					>
						<div className={classes['row']}>
							{ratings.map(({ src, id }) => (
								<Image
									src={src as any}
									key={id}
									style={{
										marginRight: id === '4' ? 0 : '0.25rem',
									}}
								/>
							))}
						</div>
						<Typography
							variant="h6"
							style={{
								marginTop: '0.5rem',
							}}
						>
							Nurse Rated Dispatch
						</Typography>
					</div>
				</div>
				<Typography
					variant="h5"
					weight="medium"
					style={{
						margin: '1rem 0',
					}}
				>
					In need of a nurse to support with a wounded patient
				</Typography>
				<div
					className={classes['row']}
					style={{
						borderBottom: '1px solid #E5E5E5',
						paddingBottom: '1rem',
					}}
				>
					<div
						style={{
							marginTop: '1rem',
						}}
					>
						<div className={classes['row']}>
							<div className={classes['icon-button']}>
								<Image
									style={{
										width: '0.75rem',
										height: '0.75rem',
									}}
									src="icThickPlusPrimary"
								/>
							</div>
							<Typography variant="h6">Hospital Name</Typography>
						</div>
						<Typography
							variant="h6"
							weight="bold"
							style={{
								marginTop: '0.5rem',
							}}
						>
							Spencer Memorial Hospital
						</Typography>
					</div>
					<div className={classes['vertical-separator']} />
					<div
						style={{
							marginTop: '1rem',
						}}
					>
						<div className={classes['row']}>
							<div className={classes['icon-button']}>
								<Image
									style={{
										width: '0.75rem',
										height: '0.75rem',
									}}
									src="icClockPrimary"
								/>
							</div>
							<Typography variant="h6">Date/Time</Typography>
						</div>
						<Typography
							variant="h6"
							weight="bold"
							style={{
								marginTop: '0.5rem',
							}}
						>
							03.01.21, 11:00AM
						</Typography>
					</div>
					<div className={classes['vertical-separator']} />

					<div
						style={{
							marginTop: '1rem',
						}}
					>
						<div className={classes['row']}>
							<div className={classes['icon-button']}>
								<Image
									style={{
										width: '0.75rem',
										height: '0.75rem',
									}}
									src="icEmail"
								/>
							</div>
							<Typography variant="h6">Contact Information</Typography>
						</div>
						<Typography
							variant="h6"
							weight="bold"
							style={{
								marginTop: '0.5rem',
							}}
						>
							+1 68 54 71 248
						</Typography>
					</div>
					<div className={classes['vertical-separator']} />
					<div
						style={{
							marginTop: '1rem',
						}}
					>
						<div className={classes['row']}>
							<div className={classes['icon-button']}>
								<Image
									style={{
										width: '1rem',
										height: '1rem',
									}}
									src="icLocation"
								/>
							</div>
							<Typography variant="h6">Dispatch Location</Typography>
						</div>
						<Typography
							variant="h6"
							weight="bold"
							style={{
								marginTop: '0.5rem',
							}}
						>
							123th Main St. BTE B6, San Francisco, Carlifornia
						</Typography>
					</div>
					<div className={classes['vertical-separator']} />
					<div
						style={{
							marginTop: '1rem',
						}}
					>
						<div className={classes['row']}>
							<div className={classes['icon-button']}>
								<Image
									style={{
										width: '0.75rem',
										height: '0.75rem',
									}}
									src="icRoomPrimary"
								/>
							</div>
							<Typography variant="h6">Room Number</Typography>
						</div>
						<Typography
							variant="h6"
							weight="bold"
							style={{
								marginTop: '0.5rem',
							}}
						>
							Floor 2, Room 123
						</Typography>
					</div>
				</div>
				<div
					className={classes['row']}
					style={{
						justifyContent: 'space-between',
						alignItems: 'flex-start',
					}}
				>
					<div style={{ width: '48%', minWidth: 400 }}>
						<div
							className={classes['wrapper']}
							style={{
								width: '100%',
								marginTop: '1.5rem',
							}}
						>
							<Typography variant="h6">Ended Dispatch: </Typography>
							<div className={classes['row']}>
								<div
									style={{
										marginTop: '1rem',
										marginRight: '1rem',
									}}
								>
									<div className={classes['row']}>
										<div className={classes['icon-button']}>
											<Image
												style={{
													width: '0.75rem',
													height: '0.75rem',
												}}
												src="icClockPrimary"
											/>
										</div>
										<Typography variant="h6">Time Spent</Typography>
									</div>
									<Typography
										variant="h5"
										weight="bold"
										style={{
											marginTop: '0.5rem',
										}}
									>
										4:15 Hours
									</Typography>
								</div>
								<div
									style={{
										marginTop: '1rem',
										marginRight: '1rem',
									}}
								>
									<div className={classes['row']}>
										<div className={classes['icon-button']}>
											<Image
												style={{
													width: '0.75rem',
													height: '0.75rem',
												}}
												src="icRoomPrimary"
											/>
										</div>
										<Typography variant="h6">Dispatch Rating</Typography>
									</div>

									<div className={classes['row']}>
										{ratings.map(({ src, id }) => (
											<Image
												src={src as any}
												key={id}
												style={{
													marginRight: '0.25rem',
												}}
											/>
										))}
									</div>
								</div>
								<div
									style={{
										marginTop: '1rem',
										marginRight: '1rem',
									}}
								>
									<div className={classes['row']}>
										<div className={classes['icon-button']}>
											<Image
												style={{
													width: '0.75rem',
													height: '0.75rem',
												}}
												src="icClockPrimary"
											/>
										</div>
										<Typography variant="h6">Break Time Spent</Typography>
									</div>
									<Typography
										variant="h5"
										weight="bold"
										style={{
											marginTop: '0.5rem',
										}}
									>
										0:15 Minutes
									</Typography>
								</div>
								<div
									style={{
										marginTop: '1rem',
										marginRight: '1rem',
									}}
								>
									<div className={classes['row']}>
										<div className={classes['icon-button']}>
											<Image
												style={{
													width: '0.75rem',
													height: '0.75rem',
												}}
												src="icClockPrimary"
											/>
										</div>
										<Typography variant="h6">Dispatch Started at</Typography>
									</div>
									<Typography
										variant="h5"
										weight="bold"
										style={{
											marginTop: '0.5rem',
										}}
									>
										11.01.21, 10:00AM
									</Typography>
								</div>
							</div>
						</div>
						<Typography
							variant="h6"
							style={{
								marginTop: '2rem',
							}}
						>
							Patient Notes:{' '}
						</Typography>
						<div
							className={classes['wrapper']}
							style={{
								width: '100%',
								marginTop: '0.5rem',
							}}
						>
							<Typography variant="h6">
								Lorem, ipsum dolor sit amet consectetur adipisicing elit. Magnam a aliquam impedit
								quisquam atque accusantium et in voluptate quam quibusdam eum, quod cum velit adipisci
								inventore, doloribus distinctio excepturi necessitatibus! Lorem ipsum dolor sit amet
								consectetur adipisicing elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
								Blanditiis doloremque dolore ullam velit magni molestiae exercitationem delectus ipsam,
								quaerat consequuntur. <br /> <br /> Lorem ipsum dolor, sit amet consectetur adipisicing
								elit. Earum illum iste voluptatem quidem pariatur id suscipit numquam error,
							</Typography>
						</div>
						<Typography
							variant="h6"
							style={{
								marginTop: '2rem',
							}}
						>
							Assigned Nurse:{' '}
						</Typography>
						<div
							className={classes['wrapper']}
							style={{
								width: '100%',
								marginTop: '0.5rem',
								padding: 0,
							}}
						>
							<div className={classes['assigned-nurse']}>
								<Image
									src="plNurse4"
									style={{
										width: '3rem',
										height: '3rem',
										borderRadius: '50%',
										margin: '0 0.5rem',
									}}
								/>
								<div>
									<Typography variant="h6" weight="bold">
										Regina Smith
									</Typography>
									<Typography variant="h6">Nurse staff</Typography>
								</div>
								<button
									className={classes['button']}
									style={{
										marginLeft: 'auto',
									}}
								>
									<Image
										src="icCall"
										style={{
											width: '1.3rem',
											height: '1.3rem',
											marginBottom: '0.25rem',
										}}
									/>
									<Typography variant="h6" weight="bold">
										Call
									</Typography>
								</button>
								<button className={classes['button']}>
									<Image
										src="icEmail"
										style={{
											width: '1.3rem',
											height: '1.3rem',
											marginBottom: '0.5rem',
										}}
									/>
									<Typography variant="h6" weight="bold">
										Message
									</Typography>
								</button>
							</div>
							<div
								className={classes['row']}
								style={{
									margin: '1rem 0',
									padding: '0 1rem',
									justifyContent: 'space-between',
								}}
							>
								<div>
									<Typography variant="h6" weight="light">
										POSITION
									</Typography>
									<Typography
										variant="h6"
										weight="medium"
										style={{
											marginTop: '0.5rem',
										}}
									>
										Nurse
									</Typography>
								</div>
								<div>
									<Typography variant="h6" weight="light">
										MOBILE NUMBER
									</Typography>
									<Typography
										variant="h6"
										weight="medium"
										style={{
											marginTop: '0.5rem',
										}}
									>
										+1 69 87 45 218
									</Typography>
								</div>
								<div>
									<Typography variant="h6" weight="light">
										JOINED
									</Typography>
									<Typography
										variant="h6"
										weight="medium"
										style={{
											marginTop: '0.5rem',
										}}
									>
										27.04.2019
									</Typography>
								</div>
							</div>
							<div
								className={classes['row']}
								style={{
									margin: '1rem 0',
									padding: '0 1rem',
								}}
							>
								<div>
									<Typography variant="h6" weight="light">
										DATE OF BIRTH
									</Typography>
									<Typography
										variant="h6"
										weight="medium"
										style={{
											marginTop: '0.5rem',
										}}
									>
										01.02.80
									</Typography>
								</div>
								<div
									style={{
										marginLeft: '1rem',
									}}
								>
									<Typography variant="h6" weight="light">
										EMAIL
									</Typography>
									<Typography
										variant="h6"
										weight="medium"
										style={{
											marginTop: '0.5rem',
										}}
									>
										reginasmith@gmail.com
									</Typography>
								</div>
							</div>
						</div>
					</div>
					<div style={{ width: '48%', minWidth: 400 }}>
						<div
							className={classes['wrapper']}
							style={{
								height: '17.5rem',
								marginTop: '1.5rem',
							}}
						>
							<Typography variant="h6">Status: </Typography>
							<div className={classes['starts-in-container']}>
								<Typography
									variant="h6"
									style={{
										alignSelf: 'center',
										textAlign: 'center',
									}}
									color="primary"
								>
									Starts in{' '}
									<span
										style={{
											fontWeight: 500,
										}}
									>
										2 days, 11:00 AM
									</span>
								</Typography>
								<div
									className={classes['row']}
									style={{ justifyContent: 'space-between', margin: '0.25rem 0' }}
								>
									<Typography variant="h6">Not started</Typography>
									<Typography variant="h6">2 of 6</Typography>
								</div>
								<div className={classes['progress-container']}>
									<div
										className={classes['progress']}
										style={{
											width: `${(2 / 6) * 100}%`,
										}}
									/>
								</div>
							</div>

							<Typography variant="h6">Timeline: </Typography>
							<div className={classes['wrapper']} style={{ marginTop: '0.5rem', flex: 1, padding: 0 }}>
								<Scrollbars>
									<div className={classes['timeline']}>
										<div
											className={classes['vertical-strip']}
											style={{
												height:
													!timeline?.length || timeline.length === 0
														? '100%'
														: `${timeline.length * 3.5}rem`,
											}}
										/>
										{timeline.map(({ id, description, location, time }) => (
											<div key={id} className={classes['row']}>
												<Typography variant="h6" className={classes['first-col']}>
													{time}
												</Typography>
												<div className={classes['dot']} />
												<div className={classes['second-col']}>
													<Typography variant="h6" weight="medium">
														{description}
													</Typography>
													<Typography variant="h6" weight="light">
														{location}
													</Typography>
												</div>
											</div>
										))}
									</div>
								</Scrollbars>
							</div>
						</div>
						<Typography
							variant="h6"
							style={{
								marginTop: '2rem',
							}}
						>
							Dispatch Notes:{' '}
						</Typography>
						<div
							className={classes['wrapper']}
							style={{
								width: '100%',
								marginTop: '0.5rem',
							}}
						>
							<Typography variant="h6">
								Lorem, ipsum dolor sit amet consectetur adipisicing elit. Magnam a aliquam impedit
								quisquam atque accusantium et in voluptate quam quibusdam eum, quod cum velit adipisci
								inventore, doloribus distinctio excepturi necessitatibus! Lorem ipsum dolor sit amet
								consectetur adipisicing elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
								Blanditiis doloremque dolore ullam velit magni molestiae exercitationem delectus ipsam,
								quaerat consequuntur. <br /> <br /> Lorem ipsum dolor, sit amet consectetur adipisicing
								elit. Earum illum iste voluptatem quidem pariatur id suscipit numquam error,
							</Typography>
						</div>
					</div>
				</div>
				<div
					className={classes['row']}
					style={{
						marginTop: '2rem',
						padding: '0 3rem 1rem 0',
					}}
				>
					<Typography
						variant="h6"
						style={{
							marginRight: '1rem',
						}}
					>
						End of Dispatch Confirmation:
					</Typography>
					<button
						className={classes['icon-button']}
						style={{
							width: '6rem',
							height: '2.5rem',
							borderRadius: '0.75rem',
						}}
					>
						<Image src="icCorrectPrimary" />
						<Typography
							variant="h6"
							color="dark"
							style={{
								marginLeft: '0.5rem',
							}}
						>
							Accept
						</Typography>
					</button>
					<button
						className={classes['icon-button']}
						style={{
							width: '6rem',
							height: '2.5rem',
							borderRadius: '0.75rem',
							marginLeft: '1rem',
							backgroundColor: '#FFEBE9',
						}}
					>
						<Image src="icCancelRed" />
						<Typography
							variant="h6"
							color="dark"
							style={{
								marginLeft: '0.5rem',
							}}
						>
							Decline
						</Typography>
					</button>
				</div>
			</div>
		</div>
	);
};

export default DispatchScreen;
