import React from 'react';
import { Color } from 'lib/components/types';
import classes from './index.module.scss';

type Variant = 'clear' | 'standard';
type Sizing = 'normal' | 'small';
interface Props extends Omit<React.HTMLProps<HTMLButtonElement>, 'start'> {
	title: string;
	variant?: Variant;
	type?: 'button' | 'submit';
	block?: boolean;
	color?: Color;
	sizing?: Sizing;
	start?: React.ReactNode;
	end?: React.ReactNode;
	isActivePrimary?: boolean;
}

const classVariantMapping: Record<Variant, string> = {
	clear: 'button-clear',
	standard: 'button-standard',
};

const Button = ({
	title,
	variant = 'clear',
	className,
	block,
	color = 'primary',
	sizing = 'normal',
	start,
	end,
	isActivePrimary,
	...props
}: Props) => {
	if (props.disabled) {
		color = 'dark';
	}
	const className_ = className ? className : '';
	const blockClass = block ? classes['button-block'] : '';

	const buttonClass = classVariantMapping[variant];
	const sizingClass = sizing ? classes[`${buttonClass}-sizing-${sizing}`] : '';
	const colorClass = color ? classes[`${buttonClass}-color-${color}`] : '';
	const isActivePrimaryClass = isActivePrimary ? classes[`button-isActivePrimary`] : '';
	const combinedClassNames = `${classes['button']} ${classes[buttonClass]} ${blockClass} ${sizingClass} ${className_} ${colorClass} ${isActivePrimaryClass}`;

	return (
		<button className={combinedClassNames} {...props}>
			{start && <span className={classes['button-start']}>{start}</span>}
			{title}
			{end && <span className={classes['button-end']}>{end}</span>}
		</button>
	);
};

export default Button;
