import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { User, Employee } from 'types';
import { AppDispatch, RootState, TopNavState, AuthenticationState, RequestState, SliceState, JobState } from './types';

//? Typed dispatch and selector hooks
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

//! Selector hooks
export function useSelectState() {
	return useTypedSelector<RootState>((state) => state);
}

export function useSelectTopNavState() {
	return useTypedSelector<TopNavState>(({ nav }) => nav);
}

export function useSelectCurrentUser() {
	return useTypedSelector<User>(({ user }) => user);
}

export function useSelectEmployees() {
	return useTypedSelector<SliceState<Employee>>(({ employees }) => employees);
}

export function useSelectEmployee() {
	const employees = useSelectEmployees();
	return function (id: number) {
		return employees.list.find((employee) => employee.id === id);
	};
}

export function useSelectJobs() {
	return useTypedSelector<JobState>(({ jobs }) => jobs);
}

export function useSelectJob() {
	const jobs = useSelectJobs();
	return function (id: number) {
		const job = jobs.list.find((job) => job.id === id);
		return job;
	};
}

export function useSelectAuth() {
	return useTypedSelector<AuthenticationState>(({ authentication }) => authentication);
}

export function useSelectRequest() {
	return useTypedSelector<RequestState>((state) => state.request);
}
