import { useWindowResize } from 'hooks/useWindowResize';
import React, { useEffect, useState } from 'react';
import Typography from '../Typography';
import classes from './index.module.scss';

interface Props {
	items: any[];
	onChangePage: (pageItems: any[]) => any;
	initialPage?: number;
	itemsPerPage?: number;
}

const PAGES_SHOWN = 4;
const Pagination = ({ items, onChangePage, initialPage = 1, itemsPerPage = 4 }: Props) => {
	const { isSmallerDevice } = useWindowResize();
	// currently selected page
	const [currentPage, setCurrentPage] = useState<number>(initialPage ?? 1);

	// total number of pages
	const [totalPages, setTotalPages] = useState<number>(Math.ceil(items.length / itemsPerPage));

	// dynamic start page on the footer
	const [startPage, setStartPage] = useState<number>();

	// dynamic end page on the footer
	const [endPage, setEndPage] = useState<number>();

	// start index of items to be shown in the current page
	const [startIndex, setStartIndex] = useState<number>();

	// end index of items to be shown in the current page
	const [endIndex, setEndIndex] = useState<number>();

	// the current pages being displayed on the pagination
	const [pages, setPages] = useState<number[]>();

	useEffect(
		function getTotalPages() {
			const totalItems = items.length;
			const totalPages_ = Math.ceil(totalItems / itemsPerPage);
			setTotalPages(totalPages_);
		},
		[items, itemsPerPage]
	);

	useEffect(
		function getStartEndPages() {
			let startPage_: number, endPage_: number;

			if (totalPages <= PAGES_SHOWN) {
				startPage_ = 1;
				endPage_ = totalPages;
			} else {
				if (currentPage <= Math.floor(PAGES_SHOWN / 2) + 1) {
					startPage_ = 1;
					endPage_ = PAGES_SHOWN;
				} else if (currentPage + Math.floor(PAGES_SHOWN / 2) - 1 >= totalPages) {
					startPage_ = totalPages - (PAGES_SHOWN - 1);
					endPage_ = totalPages;
				} else {
					startPage_ = currentPage - Math.floor(PAGES_SHOWN / 2);
					endPage_ = currentPage + (Math.floor(PAGES_SHOWN / 2) - 1);
				}
			}

			setStartPage(startPage_);
			setEndPage(endPage_);
		},
		[totalPages, itemsPerPage, currentPage]
	);

	useEffect(
		function getStartEndIndexes() {
			const totalItems = items.length;
			const startIndex_ = (currentPage - 1) * itemsPerPage;
			const endIndex_ = Math.min(startIndex_ + (itemsPerPage - 1), totalItems - 1);

			setStartIndex(startIndex_);
			setEndIndex(endIndex_);
		},
		[currentPage, itemsPerPage, items]
	);

	useEffect(
		function getPages() {
			if (startPage && endPage) {
				const pages = new Array(endPage + 1 - startPage).fill('').map((_, index) => startPage + index);
				setPages(pages);
			}
		},
		[startPage, endPage]
	);

	useEffect(
		function sendPageItems() {
			if (endIndex === undefined || !onChangePage) {
				return;
			}
			const pageOfItems = items.slice(startIndex, endIndex + 1);
			onChangePage(pageOfItems);
			console.log('set page items', pageOfItems);
		},
		[startIndex, endIndex]
	);

	const setPage = (page: number) => {
		if (page < 1 || page > totalPages) {
			return;
		}
		setCurrentPage(page);
	};

	const incrementPage = () =>
		setCurrentPage((prev) => {
			if (prev === totalPages) {
				return prev;
			}
			return prev + 1;
		});

	const decrementPage = () =>
		setCurrentPage((prev) => {
			if (prev === 1) {
				return prev;
			}
			return prev - 1;
		});

	return (
		<div style={{ display: items.length < itemsPerPage ? 'none' : 'flex' }} className={classes['bottom-toolbar']}>
			<button
				className={classes['badge']}
				onClick={() => setCurrentPage(1)}
				style={{ opacity: currentPage === 1 ? 0.5 : 1 }}
			>
				<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
					First
				</Typography>
			</button>
			<button
				onClick={decrementPage}
				className={classes['badge']}
				style={{ opacity: currentPage === 1 ? 0.5 : 1 }}
			>
				<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
					Prev.
				</Typography>
			</button>
			{pages?.map((page) => {
				const classname =
					page === currentPage ? `${classes['badge']} ${classes['active']}` : `${classes['badge']}`;
				return (
					<button className={classname} key={page.toString()} onClick={() => setPage(page)}>
						<Typography
							variant={isSmallerDevice ? 'h5' : 'h6'}
							color={page === currentPage ? 'primary' : 'darkgrey'}
						>
							{page}
						</Typography>
					</button>
				);
			})}

			<button
				onClick={incrementPage}
				className={classes['badge']}
				style={{ opacity: currentPage === totalPages ? 0.5 : 1 }}
			>
				<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
					Next
				</Typography>
			</button>
			<button
				onClick={() => setCurrentPage(totalPages)}
				className={classes['badge']}
				style={{ opacity: currentPage === totalPages ? 0.5 : 1 }}
			>
				<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
					Last
				</Typography>
			</button>
		</div>
	);
};

export default Pagination;
