import React, { useCallback, useEffect, useState } from 'react';
import { Typography, Image } from 'lib/components';
import { Job } from 'types';
import useAddIdPrefix from 'hooks/useAddIdPrefix';
import classes from '../index.module.scss';
import { CHeaderItem, CSortField, Sort } from './types';
import { jobOrderIdCompareFn, jobTimeCompareFn, jobTitleCompareFn } from './sort';
import { useHistory } from 'react-router-dom';

interface Props {
	jobs: Job[];
}

const HEADER_ITEMS: CHeaderItem[] = [
	{ label: 'Order ID', field: 'orderId', sort: 'asc' },
	{ label: 'Title', field: 'title', sort: 'asc' },
	{ label: 'End Time', field: 'title', sort: 'asc' },
];

const JobRequestTable = ({ jobs }: Props) => {
	const { push } = useHistory();
	const addPrefix = useAddIdPrefix();
	const isSmallerDevice = window.innerWidth <= 500;
	const [sortFields] = useState(
		HEADER_ITEMS.filter((item) => item.field && item.sort).reduce<Record<string, Sort>>((acc, cur) => {
			if (cur.field && cur.sort) {
				acc[cur.field] = cur!.sort;
			}
			return acc;
		}, {})
	);
	const [currentSortField, setCurrentSortField] = useState<CSortField>();
	const [sortedJobs, setSortedJobs] = useState<Job[]>(jobs);

	useEffect(() => {
		if (!sortedJobs.length) {
			setSortedJobs(jobs);
			return;
		}
		const sorted = [...sortedJobs].sort((a, b) => {
			switch (currentSortField?.sort) {
				case 'asc':
					if (currentSortField?.field === 'orderId') {
						return jobOrderIdCompareFn(a, b, 'asc');
					} else if (currentSortField?.field === 'title') {
						return jobTitleCompareFn(a, b, 'asc');
					} else if (currentSortField?.field === 'endTime') {
						return jobTimeCompareFn(a, b, 'asc');
					}

					return 0;

				case 'desc':
					if (currentSortField?.field === 'orderId') {
						return jobOrderIdCompareFn(a, b, 'desc');
					} else if (currentSortField?.field === 'title') {
						return jobTitleCompareFn(a, b, 'desc');
					} else if (currentSortField?.field === 'endTime') {
						return jobTimeCompareFn(a, b, 'desc');
					}

					return 0;

				default:
					return 0;
			}
		});

		setSortedJobs(sorted);
	}, [currentSortField, setSortedJobs, jobs]);

	const handleHeaderItemClick = useCallback(
		(headerItem: CHeaderItem) => {
			if (headerItem.field && sortFields[headerItem.field]) {
				if (headerItem.field === currentSortField?.field) {
					setCurrentSortField((prev) => {
						return {
							...prev,
							sort: prev?.sort === 'asc' ? 'desc' : 'asc',
						};
					});
				} else if (currentSortField?.field !== headerItem.field) {
					setCurrentSortField({ field: headerItem.field, sort: 'desc' });
				}
			}
		},
		[currentSortField, sortFields, setCurrentSortField]
	);

	const renderHeaderItem = (headerItem: CHeaderItem) => {
		if (!headerItem.field || !headerItem.sort) {
			return (
				<div key={Math.random()} className={classes['dashboard-dataTable-grid-headerContent']}>
					<Typography weight="light" variant={isSmallerDevice ? 'h5' : 'h6'}>
						{headerItem.label}
					</Typography>
					{/* <Image src={'icTableDown'} /> */}
				</div>
			);
		} else {
			return (
				<div
					onClick={() => handleHeaderItemClick(headerItem)}
					key={Math.random()}
					className={classes['dashboard-dataTable-grid-headerContent']}
					style={{
						cursor: 'pointer',
					}}
				>
					<Typography weight="light" variant={isSmallerDevice ? 'h5' : 'h6'}>
						{headerItem.label}
					</Typography>
					<Image
						src={'icTableDown'}
						style={{
							marginLeft: '0.5rem',
							transform:
								currentSortField?.field === headerItem.field && currentSortField.sort === 'desc'
									? 'rotate(180deg)'
									: 'initial',
						}}
					/>
				</div>
			);
		}
	};

	return (
		<div className={`${classes['dashboard-dataTable']} ${classes['dashboard-dataTable-activeJob']}`}>
			<div className={classes['dashboard-dataTable-header']}>
				<div className={classes['dashboard-dataTable-grid-completedJobs']}>
					{HEADER_ITEMS.map((item) => renderHeaderItem(item))}
				</div>
			</div>
			<div className={classes['dashboard-dataTable-rows']}>
				{sortedJobs.map((job) => (
					<div
						key={job.id}
						onClick={() => push(`/jobs/view/${job.id}`)}
						className={classes['dashboard-dataTable-grid-completedJobsItems']}
					>
						<div>
							<Typography weight="medium" variant={isSmallerDevice ? 'h5' : 'h6'}>
								{addPrefix(job.id)}
							</Typography>
						</div>
						<div>
							<Typography
								weight="medium"
								variant={isSmallerDevice ? 'h5' : 'h6'}
								style={{
									width: '12rem',
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								}}
							>
								{job.description}
							</Typography>
						</div>
						<div>
							<Typography weight="light" variant={isSmallerDevice ? 'h5' : 'h6'}>
								{job.updatedAt}
							</Typography>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default JobRequestTable;
