import React, { useEffect, useState } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { useAppDispatch, useSelectAuth, actions, useSelectCurrentUser, useSelectRequest } from 'store';
import { redirectToAuthPortal } from 'lib/redirect';
import { useAuthentication } from 'hooks/useLocalStorage';
import { isExpired, removeAuth } from 'lib/util/helpers';
import routes from 'lib/api/routes';

const Auth = () => {
	const { search } = useLocation();
	const uniqueId = new URLSearchParams(search).get('uniqueId');
	const redirectTo = new URLSearchParams(search).get('redirectTo');
	const [isAuth, setIsAuth] = useState(false);
	const [auth, setAuth] = useAuthentication();

	const dispatch = useAppDispatch();
	const { active } = useSelectRequest();
	const authentication = useSelectAuth();
	const user = useSelectCurrentUser();

	// User details request status
	useEffect(() => {
		if (active.status === 'idle') {
			return;
		}
		// Clear auth if request fails
		if (active.name === routes.USER && active.method === 'GET') {
			if (active.status === 'failed') {
				removeAuth();
				redirectToAuthPortal();
			}

			if (active.status === 'succeeded') {
				const { accessToken, expiryAt } = auth!;

				// Add auth data to redux state
				dispatch(actions.authenticationFinished({ accessToken, expiryAt }));
			}
		}
	}, [active]);

	useEffect(() => {
		if (!uniqueId) {
			if (auth && !isExpired(auth.expiryAt)) {
				const { user, ...rest } = auth;
				dispatch(actions.authenticationFinished({ ...rest }));
				dispatch(actions.userFetched(user));
			} else {
				redirectToAuthPortal();
			}
		} else {
			dispatch(actions.getAccessToken(uniqueId!));
		}
	}, [uniqueId]);

	useEffect(() => {
		if (!authentication.isAuthenticated) return;

		if (authentication.isAuthenticated) {
			const { accessToken, expiryAt } = authentication;
			setAuth({ accessToken, expiryAt, user });
			setIsAuth(true);
		}
	}, [authentication, user]);

	return isAuth ? <Redirect to={redirectTo ?? 'dashboard'} /> : <p>Loading...</p>; // TODO Add a loading screen
};

export default Auth;
