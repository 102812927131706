import { ProgressBar, Typography } from 'lib/components';
import { JobStatus, Timing } from 'lib/util';
import React from 'react';
import { Job } from 'types';
import classes from './index.module.scss';

interface Props {
	status?: 'primary' | 'warning' | 'danger';
	job: Job;
}

const JobDetailProgress = ({ status = 'primary', job }: Props) => {
	const titleColor = status === 'primary' ? 'primary' : 'dark';

	let className = classes['jobDetail-progress'];
	if (status !== 'primary') {
		className += ` ${classes['jobDetail-progress-' + status]}`;
	}

	return (
		<div className={className}>
			<Typography color={titleColor} className={classes['jobDetail-progress-title']}>
				{Timing.to(job.startingAt).includes('ago') ? 'Started ' : 'Starts '}
				<b>{Timing.to(job.startingAt)}</b>
			</Typography>
			<div className={classes['jobDetail-progress-label']}>
				<Typography>{JobStatus.progressText(job.status)}</Typography>
				<Typography>{JobStatus.progressFractional(job.status)}</Typography>
			</div>
			<div className={classes['jobDetail-progress-control']}>
				<ProgressBar variant={status} valuePercentage={JobStatus.progressPercentage(job.status)} />
			</div>
		</div>
	);
};

export default JobDetailProgress;
