import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Typography, SearchBar, Image } from 'lib/components';
import classes from './index.module.scss';
import { useAppDispatch, actions, useSelectTopNavState, useSelectCurrentUser } from 'store';
import { useWindowResize } from 'hooks/useWindowResize';
import Notification from './Notification';

const TopNav = () => {
	const { contentHeader, searchBarVisible, notificationVisible } = useSelectTopNavState();
	const user = useSelectCurrentUser();
	const dispatch = useAppDispatch();
	const { isSmallDevice } = useWindowResize();

	const {
		push,
		goBack,
		location: { pathname },
	} = useHistory();

	const openMenu = () => {
		dispatch(actions.sidenavToggled(true));
	};

	const hideBackIcon = pathname === '/dashboard' || pathname === '/staff' || pathname === '/jobs';

	return (
		<div className={classes['topNav']}>
			<div className={classes['topNav-container']}>
				<div className={classes['topNav-logo']}>
					<Link to={'/'}>LOGO</Link>
				</div>
				<div className={classes['topNav-content']}>
					{contentHeader && (
						<div className={classes['topNav-content-header']}>
							{!hideBackIcon && (
								<a onClick={goBack} className={classes['topNav-content-header-back-btn']}>
									<Image src={'icLeftCaret'} />
								</a>
							)}
							<Typography variant="h3">{contentHeader}</Typography>
						</div>
					)}
					{searchBarVisible && (
						<div className={classes['topNav-content-search']}>
							<SearchBar
								onSearch={(query) => console.log('searching ' + query)}
								placeholder="Search..."
							/>
						</div>
					)}
				</div>
				<div className={classes['topNav-right']}>
					<div className={classes['topNav-userInfo']} onClick={() => push('/profile')}>
						<Image
							className={classes['topNav-userInfo-avatar']}
							alt="avatar"
							src={user.avatar ?? 'plAvatar'}
						/>
						<div className={classes['topNav-userInfo-detail']}>
							<Typography className={classes['topNav-userInfo-name']} variant="h5">
								{user.name}
							</Typography>
							<Typography className={classes['topNav-userInfo-role']} variant="h6">
								Manager
							</Typography>
						</div>
					</div>
					{notificationVisible && <Notification />}
					{isSmallDevice && (
						<a className={classes['topNav-menuButton']} onClick={openMenu}>
							<Image src={'icMenu'} />
						</a>
					)}
				</div>
			</div>
		</div>
	);
};

export default TopNav;
