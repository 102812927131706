import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import { TopNavState } from '../types';

const initialState: TopNavState = {
	contentHeader: '',
	mapToggleVisible: false,
	notificationVisible: false,
	sidenavVisible: false,
	searchBarVisible: false,
};

export default createSlice({
	name: 'nav',
	initialState,
	reducers: {
		contentHeaderChanged: (state, { payload }: PA<string>) => {
			state.contentHeader = payload;
		},
		mapDisplayToggled: (state, { payload }: PA<boolean>) => {
			state.mapToggleVisible = payload;
		},
		searchBarDisplayToggled: (state, { payload }: PA<boolean>) => {
			state.searchBarVisible = payload;
		},
		notificationToggled: (state, { payload }: PA<boolean>) => {
			state.notificationVisible = payload;
		},
		sidenavToggled: (state, { payload }: PA<boolean>) => {
			state.sidenavVisible = payload;
		},
	},
});
