import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { ImageSource } from 'lib/components/types';
import { Image, Typography } from 'lib/components';
import classes from './index.module.scss';

interface Props {}

type SideNavItem = { to: string; icon: ImageSource; title: string };

const hospitalPortalSideNavItems: SideNavItem[] = [
	{
		to: '/dashboard',
		icon: 'icDashboard',
		title: 'Dashboard',
	},
	{
		to: '/jobs',
		icon: 'icHeadphones',
		title: 'Jobs',
	},
	{
		to: '/staff',
		icon: 'icStaff',
		title: 'Staff',
	},
];

const sideNavExtraItems: SideNavItem[] = [
	// {
	// 	to: '/settings',
	// 	icon: 'icSettings',
	// 	title: 'Settings',
	// },
	{
		to: '/logout',
		icon: 'icLogout',
		title: 'Logout',
	},
];

const SideNav = ({}: Props) => {
	const history = useHistory();
	return (
		<div className={classes['sideNav']}>
			<Typography className={classes['sideNav-title']} variant="subheading2" color="primary">
				MENU
			</Typography>
			<div className={classes['sideNav-nav']}>
				{hospitalPortalSideNavItems.map((navItem) => (
					<NavLink
						key={navItem.title}
						className={classes['sideNav-item']}
						activeClassName={classes['sideNav-item-active']}
						to={navItem.to}
					>
						<Image
							className={classes['sideNav-item-icon']}
							src={navItem.icon}
							alt={navItem.title + ' icon'}
						/>
						<Typography className={classes['sideNav-item-title']} variant="subheading1">
							{navItem.title}
						</Typography>
					</NavLink>
				))}
			</div>
			<button className={classes['sideNav-addNewDispatch']} onClick={() => history.push('/jobs/create')}>
				<Typography className={classes['sideNav-addNewDispatch-title']} variant="p">
					Add New Job
				</Typography>
				<a className={classes['sideNav-addNewDispatch-button']}>
					<Image src={'icPlusPrimary'} alt="plus icon" />
				</a>
			</button>

			<Typography className={classes['sideNav-title']} variant="subheading2" color="primary">
				EXTRA
			</Typography>
			<div className={classes['sideNav-nav']}>
				{sideNavExtraItems.map((navItem) => (
					<NavLink
						key={navItem.title}
						className={classes['sideNav-item']}
						activeClassName={classes['sideNav-item-active']}
						to={navItem.to}
					>
						<Image
							className={classes['sideNav-item-icon']}
							src={navItem.icon}
							alt={navItem.title + ' icon'}
						/>
						<Typography className={classes['sideNav-item-title']} variant="subheading1">
							{navItem.title}
						</Typography>
					</NavLink>
				))}
			</div>
		</div>
	);
};

export default SideNav;
