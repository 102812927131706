import { Job } from 'types';
import { Sort } from './types';

export const jobOrderIdCompareFn = (a: Job, b: Job, sort: Sort) => {
	if (a.id > b.id) {
		return sort === 'asc' ? 1 : -1;
	} else if (a.id < b.id) {
		return sort === 'asc' ? -1 : 1;
	}
	return 0;
};

export const jobTitleCompareFn = (a: Job, b: Job, sort: Sort) => {
	if (sort === 'asc') {
		return a.description.localeCompare(b.description) === -1 ? 1 : -1;
	} else {
		return a.description.localeCompare(b.description) === 1 ? -1 : 1;
	}
};

export const jobCurrentStatusCompareFn = (a: Job, b: Job, sort: Sort) => {
	// if (a.stock > b.stock) {
	// 	return sort === 'asc' ? 1 : -1;
	// } else if (a.stock < b.stock) {
	// 	return sort === 'asc' ? -1 : 1;
	// }
	return 0;
};

export const jobTimeCompareFn = (a: Job, b: Job, sort: Sort) => {
	const valueA = new Date(a.createdAt);
	const valueB = new Date(b.createdAt);
	if (valueA > valueB) {
		return sort === 'asc' ? 1 : -1;
	} else if (valueA < valueB) {
		return sort === 'asc' ? -1 : 1;
	}
	return 0;
};

export const jobNurseCompareFn = (a: Job, b: Job, sort: Sort) => {
	const nurseA = a.nurse;
	const nurseB = b.nurse;
	if (!nurseA || !nurseB) {
		return 0;
	}
	if (sort === 'asc') {
		return nurseA.user.name.localeCompare(nurseB.user.name) === -1 ? 1 : -1;
	} else {
		return nurseA.user.name.localeCompare(nurseB.user.name) === 1 ? -1 : 1;
	}
};
