import React from 'react';
import { JobFile } from 'types';
import FileDelete from './FileDelete';
import FileDownload from './FileDownload';

interface Props {
	files: JobFile[];
}

const Downloadable = ({ files }: Props) => (
	<>
		{files.map((file) => (
			<FileDownload key={file.id} file={file} />
		))}
	</>
);

const Deletable = ({ files }: Props) => (
	<>
		{files.map((file) => (
			<FileDelete key={file.id} file={file} />
		))}
	</>
);

export default {
	Downloadable,
	Deletable,
};
