import React, { useEffect, useRef, useState } from 'react';
import useComprehensiveTopNav from 'hooks/useComprehensiveTopNav';
import { LoadingScreen, Typography } from 'lib/components';
import classes from './index.module.scss';
import Image from 'lib/components/Image';
import { useParams } from 'react-router-dom';
import { useWindowResize } from 'hooks/useWindowResize';
import { useAppDispatch, useSelectJobs, useSelectJob, actions } from 'store';
import { Job } from 'types';
import useAddIdPrefix from 'hooks/useAddIdPrefix';
import { getStatusColor, JobStatus, Timing } from 'lib/util';
import Tag from 'lib/components/Tag';
import JobDetailProgress from './JobDetailProgress';
import FileList from './FileList';

interface Props {
	job: Job;
}

const JobScreen = () => {
	const { id } = useParams<{ id: string }>();

	const dispatch = useAppDispatch();
	const jobs = useSelectJobs();
	const getJobById = useSelectJob();

	const [job, setJob] = useState<Job>();

	useEffect(() => {
		dispatch(actions.fetchJob(parseInt(id)));
	}, []);

	useEffect(() => {
		const fetchedJob = getJobById(parseInt(id));

		if (fetchedJob) {
			setJob(fetchedJob);
		}
	}, [jobs.updatedAt]);

	if (!job) return <LoadingScreen />;

	return <JobScreenView job={job!} />;
};

const JobScreenView = ({ job }: Props) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const { isSmallDevice, isSmallerDevice } = useWindowResize();
	const addPrefix = useAddIdPrefix();

	useComprehensiveTopNav({
		contentHeader: `Job - ${addPrefix(job.id)}`,
		showMapToggle: true,
		showSearchBar: false,
	});

	return (
		<div className={classes['dispatch-view-container']} ref={containerRef}>
			<div
				style={{
					padding: isSmallDevice ? '1rem' : '1.5rem 3rem 0.75rem 3rem',
					borderBottom: '1px solid #E5E5E5',
				}}
			>
				<div
					className={classes['row']}
					style={{
						justifyContent: 'space-between',
					}}
				>
					<div className={classes['row']}>
						<div
							className={classes['badge']}
							style={{
								marginTop: '0.5rem',
								marginRight: '1rem',
							}}
						>
							<Typography>
								Order Number:{' '}
								<span
									style={{
										fontWeight: 600,
									}}
								>
									{addPrefix(job.id)}
								</span>
							</Typography>
						</div>
						<div
							className={classes['row']}
							style={{
								marginTop: '0.5rem',
							}}
						>
							<Image src="icClockPrimary" />
							<Typography
								style={{
									marginLeft: '0.5rem',
								}}
							>
								Job submitted at : {job.createdAt}
							</Typography>
						</div>
					</div>
					<div className={classes['row']}>
						<div
							className={classes['row']}
							style={{
								marginLeft: '2rem',
								marginTop: '0.5rem',
							}}
						>
							<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Status: </Typography>
							<div style={{ marginLeft: '0.5rem' }}>
								<Tag variant={getStatusColor(job.status)}>{JobStatus.text(job.status)}</Tag>
							</div>
						</div>

						<button
							className={classes['row']}
							style={{
								marginLeft: '1.5rem',
								marginTop: '0.5rem',
							}}
						>
							<Image src="icExportBlack" />
							<Typography
								variant={isSmallerDevice ? 'h5' : 'h6'}
								style={{
									marginLeft: '0.5rem',
								}}
							>
								Export
							</Typography>
						</button>
						{/* <button
							className={classes['row']}
							style={{
								marginLeft: '1.5rem',
								marginTop: '0.5rem',
							}}
							onClick={() => push(`/jobs/edit/${job.id}`)}
						>
							<Image src="icEdit" />
							<Typography
								variant={isSmallerDevice ? 'h5' : 'h6'}
								style={{
									marginLeft: '0.5rem',
								}}
							>
								Edit
							</Typography>
						</button> */}
					</div>
				</div>
				{/* <Typography
					variant="h5"
					weight="medium"
					style={{
						margin: '1rem 0',
					}}
				>
					{job.description}
				</Typography> */}
				<div className={classes['row']}>
					<div
						style={{
							marginTop: isSmallerDevice ? '2rem' : '1rem',
						}}
					>
						<div className={classes['row']}>
							<div className={classes['icon-button']}>
								<Image
									style={{
										width: '0.75rem',
										height: '0.75rem',
									}}
									src="icThickPlusPrimary"
								/>
							</div>
							<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Hospital Name</Typography>
						</div>
						<Typography
							variant={isSmallerDevice ? 'h5' : 'h6'}
							weight="bold"
							style={{
								marginTop: '0.5rem',
							}}
						>
							{job.hospital.name}
						</Typography>
					</div>
					<div className={classes['vertical-separator']} />
					<div
						style={{
							marginTop: isSmallerDevice ? '2rem' : '1rem',
						}}
					>
						<div className={classes['row']}>
							<div className={classes['icon-button']}>
								<Image
									style={{
										width: '0.75rem',
										height: '0.75rem',
									}}
									src="icClockPrimary"
								/>
							</div>
							<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Date/Time</Typography>
						</div>
						<Typography
							variant={isSmallerDevice ? 'h5' : 'h6'}
							weight="bold"
							style={{
								marginTop: '0.5rem',
							}}
						>
							{Timing.dateTimeToFormat(job.startingAt, 'DOT_FORMAT_W_TIME')}
						</Typography>
					</div>
					<div className={classes['vertical-separator']} />

					<div
						style={{
							marginTop: isSmallerDevice ? '2rem' : '1rem',
						}}
					>
						<div className={classes['row']}>
							<div className={classes['icon-button']}>
								<Image
									style={{
										width: '0.75rem',
										height: '0.75rem',
									}}
									src="icEmail"
								/>
							</div>
							<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Contact Information</Typography>
						</div>
						<Typography
							variant={isSmallerDevice ? 'h5' : 'h6'}
							weight="bold"
							style={{
								marginTop: '0.5rem',
							}}
						>
							{job.contact?.phoneNumber}
						</Typography>
					</div>
					<div className={classes['vertical-separator']} />
					<div
						style={{
							marginTop: isSmallerDevice ? '2rem' : '1rem',
						}}
					>
						<div className={classes['row']}>
							<div className={classes['icon-button']}>
								<Image
									style={{
										width: '1rem',
										height: '1rem',
									}}
									src="icLocation"
								/>
							</div>
							<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Job Location</Typography>
						</div>
						<Typography
							variant={isSmallerDevice ? 'h5' : 'h6'}
							weight="bold"
							style={{
								marginTop: '0.5rem',
							}}
						>
							{job.hospital.location}
						</Typography>
					</div>
				</div>
			</div>
			<div className={classes['jobDetail-bottomSection-1']}>
				<div className={classes['jobDetail-sectionField']}>
					<Typography>Status:</Typography>
					<div className={classes['jobDetail-sectionValue']}>
						<JobDetailProgress job={job} status="primary" />

						<div className={classes['jobDetail-timeline']}>
							{job.logs.length ? <Typography>Timeline:</Typography> : null}
							<div className={classes['jobDetail-timelineSection']}>
								{[...job.logs]
									.sort((a, b) => b.id - a.id)
									.map((logs) => (
										<div key={logs.id} className={classes['jobDetail-timelineItem']}>
											<div className={classes['jobDetail-timelineTime']}>
												<Typography>{logs.createdAt}</Typography>
											</div>
											<div className={classes['jobDetail-timelineSeparator']}>
												<span />
												<span />
											</div>
											<div className={classes['jobDetail-timelineInfo']}>
												<Typography className={classes['jobDetail-timelineDescription']}>
													{logs.message}
												</Typography>
												<Typography
													weight="light"
													className={classes['jobDetail-timelineLocation']}
												>
													{logs.location}
												</Typography>
											</div>
										</div>
									))}
							</div>
						</div>
					</div>
				</div>
				<div className={classes['jobDetail-sectionField']}>
					<Typography>Description:</Typography>
					<div className={classes['jobDetail-sectionValue']}>
						<Typography>{job.description}</Typography>
					</div>
				</div>
			</div>

			<div className={classes['jobDetail-bottomSection-2']}>
				<div className={`${classes['jobDetail-sectionField']} ${classes['jobDetail-inventory']}`}>
					<Typography>Inventory Used/Assigned:</Typography>
					<div className={classes['jobDetail-sectionValue']}>
						{job.inventory.length ? (
							<div className={classes['jobDetail-inventoryTable']}>
								<div className={classes['jobDetail-inventoryTableHeader']}>
									<div>
										<Typography>Name</Typography>
										<Image src="icCaretBlack" />
									</div>
									<div>
										<Typography>Available</Typography>
										<Image src="icCaretBlack" />
									</div>
								</div>
								<div className={classes['jobDetail-inventoryTableContent']}>
									{job.inventory.map((inventoryItem) => (
										<div
											key={inventoryItem.inventoryId}
											className={classes['jobDetail-inventoryTableRow']}
										>
											<Typography>{inventoryItem.name}</Typography>
											<Typography>{inventoryItem.amount}</Typography>
										</div>
									))}
								</div>
							</div>
						) : (
							<Typography style={{ color: 'rgba(0,0,0,0.2)' }}>
								Inventory can only be added by the Administrators and requested by the nurse while on an
								active job.
							</Typography>
						)}
					</div>
				</div>
				<div className={classes['jobDetail-sectionField']}>
					<Typography>Downloads</Typography>
					<div className={classes['jobDetail-sectionButtons']}>
						<FileList.Downloadable files={job.files ?? []} />
					</div>
				</div>
			</div>

			<div className={classes['jobDetail-bottomSection-3']}>
				<div className={`${classes['jobDetail-sectionField']}`}>
					<Typography>Nurse Notes:</Typography>
					<div className={classes['jobDetail-sectionValue']}>
						<Typography>{job.nurseNotes}</Typography>
					</div>
				</div>
				<div className={classes['jobDetail-sectionField']}>
					<Typography>Requirements:</Typography>
					<div className={classes['jobDetail-sectionValue']}>
						<div className={classes['jobDetail-requirements']}>
							{job.requirements.map(({ id, name }) => (
								<Tag key={id} variant="primary">
									{name}
								</Tag>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default JobScreen;
