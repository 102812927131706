import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useComprehensiveTopNav from 'hooks/useComprehensiveTopNav';
import { SearchBar, Typography, LoadingScreen } from 'lib/components';
import { useAppDispatch, actions, useSelectEmployees } from 'store';
import { useWindowResize } from 'hooks/useWindowResize';
import classes from './index.module.scss';
import StaffDataTable from 'lib/components/DataTables/Staff';

const EmployeesScreen = () => {
	const { push } = useHistory();
	const { isSmallDevice } = useWindowResize();

	const dispatch = useAppDispatch();
	const employees = useSelectEmployees();

	const [isDataFetched, setIsDataFetched] = useState(false);

	useEffect(() => {
		dispatch(actions.fetchEmployees());
	}, []);

	useEffect(() => {
		setIsDataFetched(true);
	}, [employees.updatedAt]);

	useComprehensiveTopNav({
		contentHeader: 'Staff',
		showMapToggle: true,
		showSearchBar: false,
	});

	if (!isDataFetched) return <LoadingScreen />;

	return (
		<div className={classes['nurses-screen-container']}>
			<div className={classes['row']}>
				<SearchBar
					onSearch={(value) => console.log({ value })}
					className={classes['search-input-container']}
					placeholder="Search..."
				/>
				<button
					className={classes['row']}
					style={{
						marginTop: '1rem',
					}}
					onClick={() => push('/staff/create')}
				>
					<Typography variant={isSmallDevice ? 'h5' : 'h6'} weight="medium">
						Add New Staff
					</Typography>
					<div className={classes['plus-icon']}>+</div>
				</button>
			</div>
			<div className={classes['nurses-list']}>
				<StaffDataTable staff={employees.list} />
			</div>
		</div>
	);
};

export default EmployeesScreen;
