import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { nanoid as ID } from 'nanoid';
import useCustomToast from 'hooks/toast';
import useComprehensiveTopNav from 'hooks/useComprehensiveTopNav';
import { useWindowResize } from 'hooks/useWindowResize';
import routes from 'lib/api/routes';
import { Image, Typography, LoadingScreen } from 'lib/components';
import { DatePicker, TextField } from 'lib/components/FormFields';
import { Timing } from 'lib/util';
import { actions, useAppDispatch, useSelectRequest, useSelectEmployees, useSelectJobs } from 'store';
import AddRequirementInput from './AddRequirementInput';
import PointOfContactSelect from './PointOfContactSelect';
import FileList from './FileList';
import classes from './index.module.scss';

const CreateJobScreen = () => {
	const toast = useCustomToast();
	const { push } = useHistory();
	const containerRef = useRef<HTMLDivElement>(null);
	const { isSmallerDevice } = useWindowResize();

	const dispatch = useAppDispatch();
	const { active } = useSelectRequest();
	const employees = useSelectEmployees();
	const { documents } = useSelectJobs();

	const documentInputRef = useRef<HTMLInputElement>(null);

	const [contactId, setContactId] = useState<number | undefined>();
	const [description, setDescription] = useState('');
	const [startDate, setStartDate] = useState<Date>(new Date());
	const [isRequirementInputOpen, setIsRequirementInputOpen] = useState(false);
	const [patientName, setPatientName] = useState('');
	const [roomNumber, setRoomNumber] = useState('');
	const [patientId, setPatientId] = useState('');
	const [orderingPhysician, setOrderingPhysician] = useState('');
	const [requirements, setRequirements] = useState<string[]>([]);

	const [isDataFetched, setIsDataFetched] = useState(false);

	useEffect(() => {
		dispatch(actions.fetchEmployees());
	}, []);

	useEffect(() => {
		setIsDataFetched(true);
	}, [employees.updatedAt]);

	useEffect(() => {
		if (active.status === 'idle') {
			return;
		}

		if (active.name === routes.UPLOAD_DOCUMENT && active.method === 'POST') {
			if (active.status === 'pending') {
				toast('Uploading Document...', 'info');
			} else if (active.status === 'failed') {
				toast(active.message, 'error');
			} else if (active.status === 'succeeded') {
				toast(active.message);
			}
		}

		if (active.name === routes.JOB && active.method === 'POST') {
			if (active.status === 'failed') {
				toast(active.message, 'error');
			} else if (active.status === 'succeeded') {
				toast(active.message);
				dispatch(actions.documentsRemoved());
				push('/jobs');
			}
		}
	}, [active]);

	const addRequirements = (requirement: string) => {
		setRequirements((requirements) => [...requirements, requirement]);
	};

	useComprehensiveTopNav({
		contentHeader: 'New Job Request',
		showSearchBar: false,
	});

	const handleCreateJob = () => {
		dispatch(
			actions.addJob({
				documents,
				description,
				requirements,
				contactId,
				startingAt: Timing.formatDateToDateTime(startDate),
				patientId: patientId !== '' ? patientId : undefined,
				patientName: patientName !== '' ? patientName : undefined,
				roomNumber: roomNumber !== '' ? roomNumber : undefined,
				orderingPhysician: orderingPhysician !== '' ? orderingPhysician : undefined,
			})
		);
	};

	const handleDocumentUpload = () => {
		const fileList = documentInputRef.current?.files as FileList;
		const file = fileList[0];
		dispatch(actions.uploadDocument(file));
	};

	if (!isDataFetched) return <LoadingScreen />;

	return (
		<div className={classes['dispatch-create-container']} ref={containerRef}>
			<Typography
				variant={isSmallerDevice ? 'h5' : 'h6'}
				weight="medium"
				style={{
					marginTop: '1rem',
				}}
			>
				Job Information
			</Typography>
			<div
				className={classes['row']}
				style={{
					justifyContent: 'space-between',
				}}
			>
				<div className={classes['row']}>
					<div
						style={{
							marginTop: '1rem',
							marginRight: '1rem',
						}}
					>
						<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
							Point of Contact
						</Typography>
						<PointOfContactSelect employees={employees.list} setValue={setContactId} />
					</div>
					<div
						style={{
							marginTop: '1rem',
							marginRight: '1rem',
						}}
					>
						<DatePicker
							value={startDate}
							label="Start Date/Time"
							setValue={setStartDate}
							placeholderText="MM/DD/YYYY"
							showTimeSelect
							dateFormat="MM/dd/yyyy hh:mm aa"
						/>
					</div>
				</div>
			</div>
			<div className={classes['row']} style={{ alignItems: 'flex-start', marginTop: '1rem' }}>
				<div style={{ margin: '0.5rem 0.5rem' }}>
					<TextField
						value={patientId}
						onChange={(e) => setPatientId(e.target.value)}
						color="primary"
						variant="labelled"
						label="Patient ID:"
					/>
				</div>
				<div style={{ margin: '0.5rem 0.5rem' }}>
					<TextField
						value={patientName}
						onChange={(e) => setPatientName(e.target.value)}
						color="primary"
						variant="labelled"
						label="Patient Name:"
					/>
				</div>
				<div style={{ margin: '0.5rem 0.5rem' }}>
					<TextField
						value={roomNumber}
						onChange={(e) => setRoomNumber(e.target.value)}
						color="primary"
						variant="labelled"
						label="Room Number:"
					/>
				</div>
				<div style={{ margin: '0.5rem 0.5rem' }}>
					<TextField
						value={orderingPhysician}
						onChange={(e) => setOrderingPhysician(e.target.value)}
						color="primary"
						variant="labelled"
						label="Ordering Physician:"
					/>
				</div>
			</div>
			<div className={classes['row']} style={{ alignItems: 'flex-start' }}>
				<div style={{ flex: 2, paddingRight: '1rem' }}>
					<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey" style={{ marginTop: '2rem' }}>
						Description:
					</Typography>
					<textarea
						className={classes['textarea']}
						placeholder="Enter the description..."
						value={description}
						onChange={(e) => setDescription(e.target.value)}
					/>
				</div>
				<div style={{ marginTop: '1rem', flex: 1 }}>
					<div
						style={{
							width: '20rem',
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							padding: '0 1rem',
						}}
					>
						<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey">
							Downloads
						</Typography>
						<button
							style={{
								width: '8rem',
								display: 'flex',
								justifyContent: 'space-between',
							}}
							onClick={() => documentInputRef.current?.click()}
						>
							<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="gray">
								Upload Document
							</Typography>
							<Image src="icExportUpArrowPrimary" />
							<input
								type="file"
								ref={documentInputRef}
								style={{ display: 'none' }}
								onChange={handleDocumentUpload}
							/>
						</button>
					</div>
					<FileList.Deletable files={documents} />
				</div>
			</div>
			<div style={{ marginTop: '1rem' }}>
				<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="darkgrey" style={{ marginTop: '2rem' }}>
					Requirements
				</Typography>
				<div
					className={classes['row']}
					style={{
						marginTop: '1rem',
					}}
				>
					{requirements.map((requirement) => (
						<div
							className={classes['badge']}
							key={ID()}
							style={{
								margin: '0 1rem 1rem 0',
							}}
						>
							<Typography variant={isSmallerDevice ? 'h5' : 'h6'} color="primary">
								{requirement}
							</Typography>
						</div>
					))}
					{!isRequirementInputOpen && (
						<button
							className={classes['badge']}
							style={{
								margin: '0 1rem 1rem 0',
								backgroundColor: '#F5F5F5',
							}}
							onClick={() => setIsRequirementInputOpen(true)}
						>
							<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>
								{requirements.length === 0 ? '+ Add Requirement' : '+ Add more'}
							</Typography>
						</button>
					)}
					{isRequirementInputOpen && (
						<AddRequirementInput setIsOpen={setIsRequirementInputOpen} action={addRequirements} />
					)}
				</div>
			</div>
			<div className={classes['row']} style={{ marginTop: '2rem' }}>
				<button
					className={classes['container']}
					style={{
						backgroundColor: '#E4F4F6',
						marginRight: '1rem',
					}}
					onClick={handleCreateJob}
				>
					<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Send Job Request</Typography>
				</button>
				<button className={classes['container']} onClick={() => push('/jobs')}>
					<Typography variant={isSmallerDevice ? 'h5' : 'h6'}>Cancel</Typography>
				</button>
			</div>
		</div>
	);
};

export default CreateJobScreen;
