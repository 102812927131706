import moment from 'moment';
import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import { RequestState, RequestInfo } from '../types';

type Payload = Pick<RequestInfo, 'name' | 'method' | 'message' | 'payload'>;
type RequestActionPayload = Pick<RequestInfo, 'name' | 'method'>;

const initialState: RequestState = {
	updatedAt: moment().valueOf(),
	active: {
		name: '',
		message: '',
		method: 'GET',
		status: 'idle',
		payload: {},
	},
	list: [],
};

export default createSlice({
	name: 'request',
	initialState,
	reducers: {
		requestStarted: (state, { payload }: PA<RequestActionPayload>) => {
			state.active = {
				...payload,
				status: 'pending',
				message: '',
				payload: {},
			};
			const list = [...state.list];
			const index = list.findIndex(({ name, method }) => name === payload.name && method === payload.method);

			if (index !== -1) {
				list.splice(index, 1);
			}

			list.push({
				...payload,
				status: 'pending',
				message: '',
				payload: {},
			});

			state.updatedAt = moment().valueOf();
			state.list = list;
		},

		requestFinished: (state, { payload }: PA<Payload>) => {
			state.active = {
				...payload,
				status: 'succeeded',
			};
			const list = [...state.list];
			const index = list.findIndex(({ name, method }) => name === payload.name && method === payload.method);

			if (index !== -1) {
				list.splice(index, 1, {
					name: payload.name,
					method: payload.method,
					status: 'succeeded',
					message: payload.message || '',
					payload: payload.payload,
				});
			}

			state.updatedAt = moment().valueOf();
			state.list = list;
		},

		requestFailed: (state, { payload }: PA<Payload>) => {
			state.active = {
				...payload,
				status: 'failed',
			};
			const list = [...state.list];
			const index = list.findIndex(({ name }) => name === payload.name);

			if (index !== -1) {
				list.splice(index, 1, {
					name: payload.name,
					method: payload.method,
					status: 'failed',
					message: payload.message || '',
					payload: payload.payload,
				});
			}

			state.updatedAt = moment().valueOf();
			state.list = list;
		},

		requestConsumed: (state, { payload }: PA<RequestActionPayload>) => {
			const list = [...state.list];
			const index = list.findIndex(({ name, method }) => name === payload.name && method === payload.method);
			const previousRequest = list[index];

			if (index !== -1 && previousRequest) {
				list.splice(index, 1, {
					...payload,
					status: previousRequest.status,
					message: previousRequest.message,
					payload: {},
				});
			}

			state.updatedAt = moment().valueOf();
			state.list = list;
		},
	},
});
