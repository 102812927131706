import React, { useCallback, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { Typography, Image } from 'lib/components';
import StaffRow from './StaffRow';
import { useWindowResize } from 'hooks/useWindowResize';
import { Employee } from 'types';
import classes from './index.module.scss';
import { HeaderItem, Sort, SortField } from './types';
import { staffIdCompareFn } from './sort';
import Pagination from 'lib/components/Pagination';

interface Props {
	staff: Employee[];
}

const HEADER_ITEMS: HeaderItem[] = [
	{ label: 'ID', field: 'id', sort: 'asc' },
	{ label: 'Avatar', field: 'avatar' },
	{ label: 'Name', field: 'name' },
	{ label: 'E-mail', field: 'email' },
	{ label: 'Phone', field: 'phone' },
];

const StaffDataTable = ({ staff }: Props) => {
	const { isSmallerDevice } = useWindowResize();
	const [sortFields] = useState(
		HEADER_ITEMS.filter((item) => item.field && item.sort).reduce<Record<string, Sort>>((acc, cur) => {
			if (cur.field && cur.sort) {
				acc[cur.field] = cur!.sort;
			}
			return acc;
		}, {})
	);
	const [currentSortField, setCurrentSortField] = useState<SortField>();
	const [sortedStaff, setSortedStaff] = useState<Employee[]>(staff);

	const sortStaff = useCallback(
		(staff: Employee[]) => {
			const sorted = [...staff].sort((a, b) => {
				switch (currentSortField?.sort) {
					case 'asc':
						if (currentSortField?.field === 'id') {
							return staffIdCompareFn(a, b, 'asc');
						}

						return 0;

					case 'desc':
						if (currentSortField?.field === 'id') {
							return staffIdCompareFn(a, b, 'desc');
						}

						return 0;

					default:
						return 0;
				}
			});
			return sorted;
		},
		[currentSortField]
	);

	useEffect(() => {
		setSortedStaff(sortStaff(staff));
	}, [staff, sortStaff]);

	useEffect(() => {
		const sorted = sortStaff(sortedStaff);

		setSortedStaff(sorted);
	}, [sortStaff, setSortedStaff]);

	const handleHeaderItemClick = useCallback(
		(headerItem: HeaderItem) => {
			if (headerItem.field && sortFields[headerItem.field]) {
				if (headerItem.field === currentSortField?.field) {
					setCurrentSortField((prev) => {
						return {
							...prev,
							sort: prev?.sort === 'asc' ? 'desc' : 'asc',
						};
					});
				} else if (currentSortField?.field !== headerItem.field) {
					setCurrentSortField({ field: headerItem.field, sort: 'desc' });
				}
			}
		},
		[currentSortField, sortFields, setCurrentSortField]
	);

	const renderHeaderItem = (headerItem: HeaderItem) => {
		if (!headerItem.field || !headerItem.sort) {
			return (
				<div key={Math.random()} className={classes['col']}>
					<Typography weight="light" variant={isSmallerDevice ? 'h5' : 'h6'}>
						{headerItem.label}
					</Typography>
					{/* <Image src={'icTableDown'} /> */}
				</div>
			);
		} else {
			return (
				<div
					onClick={() => handleHeaderItemClick(headerItem)}
					key={Math.random()}
					className={classes['col']}
					style={{
						cursor: 'pointer',
					}}
				>
					<Typography weight="light" variant={isSmallerDevice ? 'h5' : 'h6'}>
						{headerItem.label}
					</Typography>
					<Image
						src={'icTableDown'}
						style={{
							marginLeft: '0.5rem',
							transform:
								currentSortField?.field === headerItem.field && currentSortField.sort === 'desc'
									? 'rotate(180deg)'
									: 'initial',
						}}
					/>
				</div>
			);
		}
	};

	return (
		<div className={classes['inventories-datatable']}>
			<div className={classes['table-wrapper']}>
				<div className={classes['table-container']}>
					<div className={classes['toolbar']}>{HEADER_ITEMS.map((item) => renderHeaderItem(item))}</div>
					<div className={classes['container']}>
						<Scrollbars
							style={{
								flex: 1,
							}}
						>
							{sortedStaff.map((staffEmployee) => (
								<StaffRow key={staffEmployee.id} staff={staffEmployee} />
							))}
						</Scrollbars>
					</div>
				</div>
			</div>
			<Pagination
				items={staff}
				onChangePage={(pageItems: Employee[]) => setSortedStaff(sortStaff(pageItems))}
				itemsPerPage={12}
			/>
		</div>
	);
};

export default StaffDataTable;
